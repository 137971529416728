// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-App__header__3qzuo{margin:1.5em;text-align:center}.src-App__header__3qzuo a{margin:0 .3em;color:inherit;font-weight:700}.src-App__header__3qzuo a.router-link-exact-active{color:#42b983}", ""]);
// Exports
exports.locals = {
	"header": "src-App__header__3qzuo"
};
module.exports = exports;
