import _objectSpread from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * !!!慎用全局注册
 * 这里只适合注册，具体实现需拆分到单独的文件
 */

import Vue from 'vue';
import * as utils from '@/scripts/utils';
import * as constants from '@/scripts/constants';
import eventBus from '@/scripts/eventBus';
const SvgIcon = function SvgIcon() {
  return import( /* webpackChunkName: "low-priority" */'@/components/SvgIcon/index.vue');
};

/* 原型属性/方法 Vue.prototype (使用 $ 前缀) */
Vue.prototype.$env = Object.freeze(process.env);
Vue.prototype.$utils = Object.freeze(_objectSpread({}, utils));
Vue.prototype.$const = Object.freeze(_objectSpread({}, constants));
Vue.prototype.$isCancel = utils.isCancel;
Vue.prototype.$eventBus = eventBus;

/* 全局过滤器 Vue.filter */
Vue.filter('dateFormat', utils.dateFormat);

/* 全局指令 Vue.directive */

/* 全局混入 Vue.mixin */

/* 全局组件 Vue.component */
Vue.component('svg-icon', SvgIcon);

/* 小插件 Vue.use (重量级插件放主目录，如：vue-router、vuex、element-ui、i18n ...) */