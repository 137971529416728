<template>
  <div>
    <!-- 顶部导航栏 -->
    <TopNav />
    <div class="homeWrap">
      <!-- 头部大图 -->
      <div class="head">
        <div class="headContent animate__animated animate__fadeInUp">
          <div class="title">
            <div class="mainTitle">易兴数据恢复软件</div>
            <div class="miniTitle">（官方正版）</div>
          </div>
          <div class="describe">
            简单易用的数据恢复工具，快速恢复电脑、回收站、U盘、硬盘、
            内存卡等设备数据
          </div>
          <div
            class="downLoad animate__animated animate__headShake animate__delay-1s"
          >
            <img class="btnImg" src="../../static/img/downLoad.png" alt="" />
            <img
              src="https://cdn-sem-themes.aunbox.cn/themes/datarecovery/win_pinpai/img/pic_guang.png"
              alt=""
              class="guang"
            />
          </div>
          <div class="adapter">适配系统：XP / Win7 / Win8 / Win10 / Win11</div>
        </div>
      </div>
      <!-- 数据恢复类型 -->
      <div class="dataType">
        <section class="dataSection animate__animated animate__fadeInUp">
          <div class="title">数据恢复类型</div>
          <div class="describe">
            易兴数据恢复软件作为一款专业好用的数据恢复软件，帮助用户恢复各种丢失的数据。快速扫描及预览，简单三步恢复丢失的数据
          </div>
        </section>
        <div class="content" :class="isTargetElement6Visible ? 'animate__animated animate__fadeInUp' : ''" ref="targetElement6">
          <div class="contentItem">
            <div class="icon">
              <img src="../../static/img/U盘数据恢复icon.png" alt="" />
            </div>
            <div class="text">U盘数据恢复</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img src="../../static/img/回收站数据恢复icon.png" alt="" />
            </div>
            <div class="text">回收站数据恢复</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img src="../../static/img/移动硬盘数据恢复icon.png" alt="" />
            </div>
            <div class="text">移动硬盘数据恢复</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img
                src="../../static/img/电脑／计算机存储文件恢复icon.png"
                alt=""
              />
            </div>
            <div class="text">电脑/计算机存储文件恢复</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img src="../../static/img/图片数据恢复.png" alt="" />
            </div>
            <div class="text">SD/图片数据恢复</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img src="../../static/img/SD／内存卡数据恢复icon.png" alt="" />
            </div>
            <div class="text">SD/内存卡数据恢复</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img src="../../static/img/磁盘分区备份还原icon.png" alt="" />
            </div>
            <div class="text">磁盘分区备份还原</div>
          </div>
          <div class="contentItem">
            <div class="icon">
              <img
                src="../../static/img/相机／行车记录仪数据恢复icon .png"
                alt=""
              />
            </div>
            <div class="text">相机/行车记录仪数据恢复</div>
          </div>
        </div>
      </div>
      <!-- 恢复文件类型 -->
      <div class="fileType">
        <section
          class="fileSection"
          :class="
            isTargetElementVisible ? 'animate__animated animate__fadeInUp' : ''
          "
          ref="targetElement"
        >
          <div class="title">恢复文件类型</div>
          <div class="describe">
            图片、视频、音频、文件夹、压缩包、文档...支持恢复1000+种文件格式
          </div>
        </section>

        <div class="content" :class="
            isTargetElement7Visible ? 'animate__animated animate__fadeInUp' : ''
          "
          ref="targetElement7">
          <div class="contentItem">
            <div class="top">
              <div class="icon">
                <img src="../../static/img/图片格式icon.png" alt="" />
              </div>
              <div class="name">图片格式</div>
            </div>
            <div class="bottom">
              Gif、Jpg、Jpeg、Psd、Bmp、Cam、Dpx、
              Dxf、Emf、Fh5、Swf、Orf、Raf、Raw、
              X3f、Mrw、Crw...
            </div>
          </div>
          <div class="contentItem">
            <div class="top">
              <div class="icon">
                <img src="../../static/img/视频格式icon.png" alt="" />
              </div>
              <div class="name">音频格式</div>
            </div>
            <div class="bottom">
              常用的音乐和语音文件，包括：Mp3、Wav、
              Wma、Aif、Flp、Mid、Flac、Ra...
            </div>
          </div>
          <div class="contentItem">
            <div class="top">
              <div class="icon">
                <img src="../../static/img/文档格式icon.png" alt="" />
              </div>
              <div class="name">文档格式</div>
            </div>
            <div class="bottom">
              Doc、Docx、Ppt、Chm、One、Wpd、
              Xpt、Xls...
            </div>
          </div>
          <div class="contentItem">
            <div class="top">
              <div class="icon">
                <img src="../../static/img/压缩文件icon.png" alt="" />
              </div>
              <div class="name">压缩文件</div>
            </div>
            <div class="bottom">
              常用的压缩文件，包括：Rar、Zip、7z
              Arj、Cab、Sit...
            </div>
          </div>
          <div class="contentItem">
            <div class="top">
              <div class="icon">
                <img src="../../static/img/视频格式icon.png" alt="" />
              </div>
              <div class="name">视频格式</div>
            </div>
            <div class="bottom">
              Mp4、Avi、Mov、Wmv、Flv、M2ts、Rm、
              Xv、Rdc、Psp、Veg、Asf、Mkv、3gp...
            </div>
          </div>
          <div class="contentItem">
            <div class="top">
              <div class="icon">
                <img src="../../static/img/文档格式icon.png" alt="" />
              </div>
              <div class="name">其他文件</div>
            </div>
            <div class="bottom">
              D2s、Djv、Dat、Dwg、Elf、Exe、Indd、
              Itu...
            </div>
          </div>
        </div>
      </div>
      <!-- 三步恢复 -->
      <div class="recoverySteps">
        <section :class="isTargetElement2Visible ? 'animate__animated animate__fadeInUp' : ''" ref="targetElement2">
          <div class="title" >
          易兴数据恢复软件三步恢复数据
        </div>
        </section>
        <section :class="isTargetElement8Visible ? 'animate__animated animate__fadeInUp' : '' " ref="targetElement8">
          <div class="content" >
          <div
            class="firtstStep contentItem"
            @click="activeIndex = 0"
          >
            <div class="circle">第一步</div>
            <div class="imageText">
              <div class="left">快速扫描</div>
              <div class="icon">
                <img src="../../static/img/快速扫描.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="secondStep contentItem"
            @click="activeIndex = 1"
          >
            <div class="circle">第二步</div>
            <div class="imageText">
              <div class="left">恢复前预览</div>
              <div class="icon">
                <img src="../../static/img/预览.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="thirdStep contentItem"
            @click="activeIndex = 2"
          >
            <div class="circle">第三步</div>
            <div class="imageText">
              <div class="left">一键恢复数据</div>
              <div class="icon">
                <img src="../../static/img/恢复.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        </section>
      </div>
      <!-- 评论 -->
      <div class="comment">
        <section
          class="commentSection"
          :class="
            isTargetElement3Visible ? 'animate__animated animate__fadeInUp' : ''
          "
          ref="targetElement3"
        >
          <div class="title">用户评价</div>
          <div class="secondTitle">我们说的都不算，用户的体验才是最好的广告</div>
          <div class="content" :class="
            isTargetElement9Visible ? 'animate__animated animate__fadeInUp' : ''
          "
          ref="targetElement9">
            <div class="contentItem">
              <div class="commentText">
                <div class="test">
                  <div class="triangle"></div>
                </div>
                <div class="text">
                  电脑太卡了总想清空回收站， 重要的文件被误删除了，本
                  以为是找不回来了，没想到 一搜索，还真有能恢复的，
                  而且恢复的很完整，很棒了！
                </div>
              </div>
              <div class="commentPeople">
                <div class="avatar">
                  <img src="../../static/img/头像1.png" alt="" />
                </div>
                <div class="scoreAndName">
                  <div class="name">张歆然</div>
                  <div class="score">
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星 (1).png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="contentItem">
              <div class="commentText">
                <div class="test">
                  <div class="triangle"></div>
                </div>
                <div class="text">
                  软件成功恢复了我的重要文件， 不然就严重影响工作了，还可
                  以自己动手操作，不用去店里 维修，不用担心安全信息泄露 的问题,非常的好用安全。
                </div>
              </div>
              <div class="commentPeople">
                <div class="avatar">
                  <img src="../../static/img/头像2.png" alt="" />
                </div>
                <div class="scoreAndName">
                  <div class="name">李源键</div>
                  <div class="score">
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="contentItem">
              <div class="commentText">
                <div class="test">
                  <div class="triangle"></div>
                </div>
                <div class="text">
                  很赞！目前是我使用过几款工 具软件中比较好的一款数据恢
                  复软件，很多时候的一些重要 的东西（如图片、文件等）都
                  能轻松的帮我找回！
                </div>
              </div>
              <div class="commentPeople">
                <div class="avatar">
                  <img src="../../static/img/头像3.png" alt="" />
                </div>
                <div class="scoreAndName">
                  <div class="name">刘欣漪</div>
                  <div class="score">
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星 (1).png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="contentItem">
              <div class="commentText">
                <div class="test">
                  <div class="triangle"></div>
                </div>
                <div class="text">
                  其实在数据丢失后，我先用过 其他的数据恢复软件，结果没
                  有什么效果，之后就用这个软 件进行了恢复了，相比之前的
                  软件节省很多时间。
                </div>
              </div>
              <div class="commentPeople">
                <div class="avatar">
                  <img src="../../static/img/头像4.png" alt="" />
                </div>
                <div class="scoreAndName">
                  <div class="name">张天宇</div>
                  <div class="score">
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星.png" alt="" />
                    <img src="../../static/img/星星 (1).png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- 下载 -->
      <div class="downLoad">
        <section
          class="downloadSection"
          :class="
            isTargetElement5Visible ? 'animate__animated animate__fadeInUp' : ''
          "
          ref="targetElement5"
        >
          <div class="title">
            <div class="icon">
              <img src="../../static/img/dataReturn.png" alt="" />
            </div>
            <div class="text">易兴数据恢复软件</div>
          </div>
          <div class="downloadNum">
            全球超过 <span>1,231,437</span> 用户下载使用
          </div>
          <div
            class="bottomDownLoad animate__animated animate__headShake animate__delay-1s"
          >
            <img class="btnImg" src="../../static/img/downLoad.png" alt="" />
            <img
              src="https://cdn-sem-themes.aunbox.cn/themes/datarecovery/win_pinpai/img/pic_guang.png"
              alt=""
              class="guang"
            />
          </div>
        </section>
      </div>
      <!-- 底部导航 -->
      <BottomNav />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      isTargetElementVisible: false,
      isTargetElement2Visible: false,
      isTargetElement3Visible: false,
      // isTargetElement4Visible: false,
      isTargetElement5Visible: false,
      isTargetElement6Visible: false,
      isTargetElement7Visible: false,
      isTargetElement8Visible: false,
      isTargetElement9Visible: false,
    }
  },
  methods: {
    toggleActive(element) {
      var elements = document.querySelectorAll('.contentItem')
      elements.forEach(function (el) {
        el.classList.remove('active')
      })
      element.classList.add('active')
    },
  },
  mounted() {
    // 获取目标元素
    const targetElement = this.$refs.targetElement
    const targetElement2 = this.$refs.targetElement2
    const targetElement3 = this.$refs.targetElement3
    // const targetElement4 = this.$refs.targetElement4
    const targetElement5 = this.$refs.targetElement5
    const targetElement6 = this.$refs.targetElement6
    const targetElement7 = this.$refs.targetElement7
    const targetElement8 = this.$refs.targetElement8
    const targetElement9 = this.$refs.targetElement9
    const targetElement11 = this.$refs.targetElement11

    // 创建 Intersection Observer 实例
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElementVisible = true
          console.log('进入了')
        } else {
          // this.isTargetElementVisible = false
          console.log('离开了')
        }
      })
    })
    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement2Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    const observer3 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement3Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    // const observer4 = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting) {
    //       this.isTargetElement4Visible = true
    //     } else {
    //       // this.isElement2Visible = false
    //     }
    //   })
    // })
    const observer5 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement5Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    const observer6 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement6Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    const observer7 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement7Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    const observer8 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement8Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    const observer9 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isTargetElement9Visible = true
        } else {
          // this.isElement2Visible = false
        }
      })
    })
    observer.observe(targetElement)
    observer2.observe(targetElement2)
    observer3.observe(targetElement3)
    // observer4.observe(targetElement4)
    observer5.observe(targetElement5)
    observer6.observe(targetElement6)
    observer7.observe(targetElement7)
    observer8.observe(targetElement8)
    observer9.observe(targetElement9)
  },
}
</script>

<style lang="less" scoped>
@keyframes guang {
  0% {
    left: -150px;
  }
  100% {
    left: 200px;
  }
}


/* 小屏幕（手机）样式 */
@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
    .homeWrap {
      background: rgba(55, 133, 239, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 80px;
      .head {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        width: 100%;
        opacity: 1;
        background-image: url('../../static/img/bg.png');
        .headContent {
          margin-top: 20px;
          // background: url('../../static/img/2222.png')  no-repeat center center / 90%;
          width: 100%;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            margin-bottom: 24px;
            display: flex;
            .mainTitle {
              font-size: 1.5rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              color: #ffffff;
            }
            .miniTitle {
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              font-size: 1rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              display: none;
            }
          }
          .describe {
            margin-bottom: 72px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 45px;
            width: 100%;
            text-align: center;
          }
          .downLoad {
            overflow: hidden;
            position: relative;
            cursor: pointer;
            margin-bottom: 62px;
            width: 10rem;
            height: auto;
            background: #1bb7f9;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            background-image: url('../../static/img/downLoad.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            .btnImg {
              width: 100%;
              height: auto;
            }
            .guang {
              position: absolute;
              left: 0;
              top: 0;
              animation: guang 1.2s 1s infinite both;
              width: 100%;
              height: 100%;
            }
          }
          .adapter {
            width: 100%;
            height: auto;
            font-size: .9rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
          }
        }
      }
      //恢复数据类型
      .dataType {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        width: 100%;
        height: auto;
        background: rgba(55, 133, 239, 0.1);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .dataSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            text-align: center;
            width: 100%;
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .describe {
            text-align: center;
            margin-top: 24px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .content {
          box-sizing: border-box;
          height: auto;
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          .contentItem {
            margin-bottom: 1rem;
            box-sizing: border-box;
            width: 180px;
            height: 180px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-shadow: 0 2px 20px 1px rgba(116,165,219,.16);
            .icon {
              width: 80px;
              height: 80px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              margin-top: 1rem;
            }
          }
        }
      }
      //恢复文件类型
      .fileType {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .fileSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .describe {
            width: 100%;
            text-align: center;
            margin-top: 24px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #666666;
            height: auto;
          }
        }

        .content {
          box-sizing: border-box;
          height: auto;
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          .contentItem {
            margin-bottom: 1rem;
            box-sizing: border-box;
            height: 174px;
            width: 16rem;
            .top {
              display: flex;
              // justify-content: center;
              align-items: center;
              .icon {
              }
              .name {
                margin-left: 24px;
                font-weight: bold;
              }
            }
            .bottom {
              // width: 100%;
              padding-left: 84px;
            }
          }
          // .contentItem:nth-child(even) {
          //   margin-left: 396px;
          // }
        }
      }
      //三步恢复
      .recoverySteps {
        box-sizing: border-box;
        padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: rgba(55, 133, 239, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          width: 100%;
          text-align: center;
          margin-top: 120px;
          font-size: 2rem;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          position: relative;
          z-index: 99;
        }
        .content {
          width: 100%;
          margin-top: 2rem;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .contentItem {
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            width: 50%;
            height: auto;
            background: #ffffff;
            border-radius: 16px 16px 16px 16px;
            margin-bottom: 1rem;
            .circle {
              text-align: center;
              line-height: 110px;
              position: absolute;
              top: -5vw;
              left: 50%;
              transform: translateX(-50%);
              width: 10vw;
              height: 10vw;
              background: #ffffff;
              box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
              border-radius: 50%;
              font-size: .5rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              color: #333333;
              line-height: 10vw;
            }
            .imageText {
              height: 100%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              .left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 0.8rem;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 500;
                color: #333333;
              }
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100px;
                img {
                  // width: 100%;
                  height: 80%;
                }
              }
            }
          }
          .active {
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            border: 2px solid #1bb7f9;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          .secondStep {
            margin-top: 25px;
          }
          .thirdStep {
            margin-top: 25px;
          }
        }
      }
      //评论
      .comment {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .commentSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .content {
            width: 100%;
            margin-top: 2rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;

            .contentItem {
              display: flex;
              flex-direction: column;
              .commentText {
                border-radius: 3px;
                position: relative;
                max-width: 260px;
                height: auto;
                // background: rgba(55, 133, 239);
                display: flex;
                // justify-content: center;
                // align-items: center;
                .test {
                  opacity: 0.05;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  // z-index: -100;
                  background: rgba(55, 133, 239);
                  .triangle {
                    transform: rotate(250deg);
                    bottom: 30px;
                    left: 10px;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-right: 200px solid transparent;
                    border-right: 200px solid transparent;
                    border-top: 100px solid rgba(55, 133, 239);
                  }
                }
                .text {
                  font-size: 0.8rem;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #515760;
                  opacity: inherit;
                  line-height: 30px;
                  padding: 36px 26px;
                }
              }
              .commentPeople {
                display: flex;

                margin-top: 66px;
                .avatar {
                  border-radius: 50%;
                }
                .scoreAndName {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  margin-left: 24px;
                  .name {
                    font-size: 20px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #666666;
                  }
                  .score {
                    margin-top: 6px;
                  }
                }
              }
            }
          }
        }
      }
      //下载
      .downLoad {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(55, 133, 239, 0.1);
        .downloadSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          .title {
            margin-top: 2rem;
            .icon {
              width: 3rem;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            font-size: 1.5rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
              margin-left: 1rem;
            }
          }
          .downloadNum {
            margin-top: 2rem;
            font-size: 1.3rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #6e8db8;
            span {
              color: #fb5600;
              font-weight: bold;
            }
          }
          // .downloadBtn {
          //   overflow: hidden;
          //   position: relative;
          //   cursor: pointer;
          //   margin-top: 72px;
          //   width: 318px;
          //   height: 106px;
          //   background-image: url('../../static/img/downLoad.png');
          //   img {
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     animation: guang 1.2s 1s infinite both;
          //     width: 100%;
          //     height: 100%;
          //   }
          // }
          .bottomDownLoad {
            margin-top: 2rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            margin-bottom: 2rem;
            width: 10rem;
            height: auto;
            background: #1bb7f9;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            background-image: url('../../static/img/downLoad.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            .btnImg {
              width: 100%;
              height: auto;
            }
            .guang {
              position: absolute;
              left: 0;
              top: 0;
              animation: guang 1.2s 1s infinite both;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  /* 在720px及以下屏幕宽度应用的样式 */
}

/* 小屏幕（手机）样式 */
@media screen and (min-width: 481px) and (max-width: 718px) {
  html {
    font-size: 14px;
    .homeWrap {
      background: rgba(55, 133, 239, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 80px;
      .head {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        width: 100%;
        opacity: 1;
        background-image: url('../../static/img/bg.png');
        .headContent {
          margin-top: 20px;
          // background: url('../../static/img/2222.png')  no-repeat center center / 90%;
          width: 100%;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            margin-bottom: 24px;
            display: flex;
            .mainTitle {
              font-size: 1.5rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              color: #ffffff;
            }
            .miniTitle {
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              font-size: 1rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              display: none;
            }
          }
          .describe {
            margin-bottom: 72px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 45px;
            width: 100%;
            text-align: center;
          }
          .downLoad {
            overflow: hidden;
            position: relative;
            cursor: pointer;
            margin-bottom: 62px;
            width: 10rem;
            height: auto;
            background: #1bb7f9;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            background-image: url('../../static/img/downLoad.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            .btnImg {
              width: 100%;
              height: auto;
            }
            .guang {
              position: absolute;
              left: 0;
              top: 0;
              animation: guang 1.2s 1s infinite both;
              width: 100%;
              height: 100%;
            }
          }
          .adapter {
            width: 100%;
            height: auto;
            font-size: .9rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
          }
        }
      }
      //恢复数据类型
      .dataType {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        width: 100%;
        height: auto;
        background: rgba(55, 133, 239, 0.1);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .dataSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            text-align: center;
            width: 100%;
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .describe {
            text-align: center;
            margin-top: 24px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .content {
          box-sizing: border-box;
          height: auto;
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-around;
          align-items: center;
          .contentItem {
            margin-bottom: 1rem;
            box-sizing: border-box;
            // width: 180px;
            margin-left: 5%;
            width: 30%;
            height: 180px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-shadow: 0 2px 20px 1px rgba(116,165,219,.16);
            &:nth-child(3n - 2) {
              margin-left: 0;
            }
            .icon {
              width: 80px;
              height: 80px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              margin-top: 1rem;
              text-align: center;
            }
          }
        }
      }
      //恢复文件类型
      .fileType {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .fileSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .describe {
            width: 100%;
            text-align: center;
            margin-top: 24px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #666666;
            height: auto;
          }
        }

        .content {
          box-sizing: border-box;
          height: auto;
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          .contentItem {
            margin-bottom: 1rem;
            box-sizing: border-box;
            height: 174px;
            width: 16rem;
            .top {
              display: flex;
              // justify-content: center;
              align-items: center;
              .icon {
              }
              .name {
                margin-left: 24px;
                font-weight: bold;
              }
            }
            .bottom {
              // width: 100%;
              padding-left: 84px;
            }
          }
          // .contentItem:nth-child(even) {
          //   margin-left: 396px;
          // }
        }
      }
      //三步恢复
      .recoverySteps {
        box-sizing: border-box;
        padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: rgba(55, 133, 239, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          width: 100%;
          text-align: center;
          margin-top: 120px;
          font-size: 2rem;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          position: relative;
          z-index: 99;
        }
        .content {
          width: 100%;
          margin-top: 5rem;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .contentItem {
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            width: 280px;
            height: auto;
            background: #ffffff;
            border-radius: 16px 16px 16px 16px;
            margin-bottom: 1rem;
            .circle {
              text-align: center;
              position: absolute;
              top: -35px;
              left: 50%;
              transform: translateX(-50%);
              width: 70px;
              height: 70px;
              background: #ffffff;
              box-shadow: 0 0.1875rem 0.375rem 0.0625rem rgba(0, 0, 0, 0.16);
              border-radius: 50%;
              font-size: 0.9rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              color: #333333;
              line-height: 70px;
            }
            .imageText {
              height: 100%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              .left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 1.0rem;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: bold;
                color: #333333;
              }
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 150px;
                img {
                  width: 100%;
                  // height: 100%;
                }
              }
            }
          }
          .active {
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            border: 2px solid #1bb7f9;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          .secondStep {
            margin-top: 35px;
          }
          .thirdStep {
            margin-top: 35px;
          }
        }
      }
      //评论
      .comment {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .commentSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .content {
            width: 100%;
            margin-top: 2rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;

            .contentItem {
              display: flex;
              flex-direction: column;
              .commentText {
                border-radius: 3px;
                position: relative;
                width: 260px;
                height: auto;
                // background: rgba(55, 133, 239);
                display: flex;
                // justify-content: center;
                // align-items: center;
                .test {
                  opacity: 0.05;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  // z-index: -100;
                  background: rgba(55, 133, 239);
                  .triangle {
                    transform: rotate(250deg);
                    bottom: 30px;
                    left: 10px;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-right: 200px solid transparent;
                    border-right: 200px solid transparent;
                    border-top: 100px solid rgba(55, 133, 239);
                  }
                }
                .text {
                  font-size: 0.8rem;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #515760;
                  opacity: inherit;
                  line-height: 30px;
                  padding: 36px 26px;
                }
              }
              .commentPeople {
                display: flex;

                margin-top: 66px;
                .avatar {
                  border-radius: 50%;
                }
                .scoreAndName {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  margin-left: 24px;
                  .name {
                    font-size: 20px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #666666;
                  }
                  .score {
                    margin-top: 6px;
                  }
                }
              }
            }
          }
        }
      }
      //下载
      .downLoad {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(55, 133, 239, 0.1);
        .downloadSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          .title {
            margin-top: 2rem;
            .icon {
              width: 3rem;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            font-size: 1.5rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
              margin-left: 1rem;
            }
          }
          .downloadNum {
            margin-top: 2rem;
            font-size: 1.3rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #6e8db8;
            span {
              color: #fb5600;
              font-weight: bold;
            }
          }
          // .downloadBtn {
          //   overflow: hidden;
          //   position: relative;
          //   cursor: pointer;
          //   margin-top: 72px;
          //   width: 318px;
          //   height: 106px;
          //   background-image: url('../../static/img/downLoad.png');
          //   img {
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     animation: guang 1.2s 1s infinite both;
          //     width: 100%;
          //     height: 100%;
          //   }
          // }
          .bottomDownLoad {
            margin-top: 2rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            margin-bottom: 2rem;
            width: 10rem;
            height: auto;
            background: #1bb7f9;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            background-image: url('../../static/img/downLoad.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            .btnImg {
              width: 100%;
              height: auto;
            }
            .guang {
              position: absolute;
              left: 0;
              top: 0;
              animation: guang 1.2s 1s infinite both;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  /* 在720px及以下屏幕宽度应用的样式 */
}
/* 小屏幕（手机）样式 */
@media screen and (min-width: 719px) and (max-width: 1199px) {
  html {
    font-size: 14px;
    .homeWrap {
      background: rgba(55, 133, 239, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-top: 80px;
      .head {
        box-sizing: border-box;
        // padding:10px;
        overflow: hidden;
        width: 100%;
        opacity: 1;
        // background-image: url('../../static/img/bg.png');
        // background-size: 100% auto;
        // background-repeat: no-repeat;
        .headContent {
          // margin-top: 20px;
          // background: url('../../static/img/2222.png')  no-repeat center center / 90%;
          background: linear-gradient(270deg, #1BB7F9 0%, #5554E6 100%);
          width: 100%;
          box-sizing: border-box;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            margin-bottom: 24px;
            display: flex;
            .mainTitle {
              font-size: 2.5rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 500;
              color: #ffffff;
            }
            .miniTitle {
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              font-size: 2rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              display: none;
            }
          }
          .describe {
            margin-bottom: 72px;
            font-size: 1.5rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 45px;
            width: 100%;
            text-align: center;
          }
          .downLoad {
            overflow: hidden;
            position: relative;
            cursor: pointer;
            margin-bottom: 62px;
            width: 10rem;
            height: auto;
            background: #1bb7f9;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            background-image: url('../../static/img/downLoad.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            .btnImg {
              width: 100%;
              height: auto;
            }
            .guang {
              position: absolute;
              left: 0;
              top: 0;
              animation: guang 1.2s 1s infinite both;
              width: 100%;
              height: 100%;
            }
          }
          .adapter {
            width: 100%;
            height: auto;
            font-size: 1.5rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
          }
        }
      }
      //恢复数据类型
      .dataType {
        box-sizing: border-box;
        padding:10px;
        overflow: hidden;
        width: 100%;
        height: auto;
        background: rgba(55, 133, 239, 0.1);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .dataSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            text-align: center;
            width: 100%;
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .describe {
            text-align: center;
            margin-top: 24px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .content {
          box-sizing: border-box;
          height: auto;
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          .contentItem {
            padding: 4rem 0px;
            margin-bottom: 1rem;
            // margin-left: 1rem;
            box-sizing: border-box;
            width: 24%;
            height: auto;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;
            box-shadow: 0 2px 20px 1px rgba(116,165,219,.16);
            .icon {
              width: 80px;
              height: 80px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .text {
              margin-top: 1rem;
            }
          }
        }
      }
      //恢复文件类型
      .fileType {
        box-sizing: border-box;
        padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background-color: #fff;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .fileSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .describe {
            width: 100%;
            text-align: center;
            margin-top: 24px;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #666666;
            height: auto;
          }
        }

        .content {
          box-sizing: border-box;
          height: auto;
          width: 100%;
          margin-top: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          .contentItem {
            margin-bottom: 1rem;
            box-sizing: border-box;
            height: 174px;
            width: 33%;
            .top {
              display: flex;
              // justify-content: center;
              align-items: center;
              .icon {
              }
              .name {
                font-size: 16px;
                margin-left: 24px;
                font-weight: bold;
              }
            }
            .bottom {
              // width: 100%;
              font-size: 14px;
              padding-left: 84px;
            }
          }
          // .contentItem:nth-child(even) {
          //   margin-left: 396px;
          // }
        }
      }
      //三步恢复
      .recoverySteps {
        box-sizing: border-box;
        padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: rgba(55, 133, 239, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          width: 100%;
          text-align: center;
          margin-top: 120px;
          font-size: 2rem;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          position: relative;
          z-index: 99;
        }
        .content {
          width: 100%;
          margin-top: 5rem;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .contentItem {
              &:hover {
              border-radius: 16px 16px 16px 16px;
              opacity: 1;
              border: 2px solid #1bb7f9;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            }
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            width: 330px;
            height: auto;
            background: #ffffff;
            border-radius: 16px 16px 16px 16px;
            margin-bottom: 1rem;
            .circle {
              text-align: center;
              position: absolute;
              top: -40px;
              left: 50%;
              transform: translateX(-50%);
              width: 80px;
              height: 80px;
              background: #ffffff;
              box-shadow: 0 0.1875rem 0.375rem 0.0625rem rgba(0, 0, 0, 0.16);
              border-radius: 50%;
              font-size: 0.9rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              color: #333333;
              line-height: 80px;
            }
            .imageText {
              height: 100%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              .left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 1.0rem;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: bold;
                color: #333333;
              }
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 150px;
                img {
                  width: 100%;
                  // height: 100%;
                }
              }
            }
          }
          .active {
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            border: 2px solid #1bb7f9;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          .secondStep {
            margin-top: 35px;
          }
          .thirdStep {
            margin-top: 35px;
          }
        }
      }
      //评论
      .comment {
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .commentSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            margin-top: 120px;
            font-size: 2rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .content {
            width: 100%;
            margin-top: 2rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;

            .contentItem {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              // margin-left: 1rem;
              width: 50%;
              .commentText {
                border-radius: 3px;
                position: relative;
                width: 260px;
                height: auto;
                // background: rgba(55, 133, 239);
                display: flex;
                // justify-content: center;
                // align-items: center;
                .test {
                  opacity: 0.05;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  // z-index: -100;
                  background: rgba(55, 133, 239);
                  .triangle {
                    transform: rotate(250deg);
                    bottom: 30px;
                    left: 10px;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-right: 200px solid transparent;
                    border-right: 200px solid transparent;
                    border-top: 100px solid rgba(55, 133, 239);
                  }
                }
                .text {
                  font-size: 0.8rem;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #515760;
                  opacity: inherit;
                  line-height: 30px;
                  padding: 36px 26px;
                }
              }
              .commentPeople {
                display: flex;

                margin-top: 66px;
                .avatar {
                  border-radius: 50%;
                }
                .scoreAndName {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;
                  margin-left: 24px;
                  .name {
                    font-size: 20px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #666666;
                  }
                  .score {
                    margin-top: 6px;
                  }
                }
              }
            }
          }
        }
      }
      //下载
      .downLoad {
        box-sizing: border-box;
      padding:10px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(55, 133, 239, 0.1);
        .downloadSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          .title {
            margin-top: 2rem;
            .icon {
              width: 3rem;
              height: auto;
              img {
                width: 100%;
                height: 100%;
              }
            }
            font-size: 1.5rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
            display: flex;
            justify-content: center;
            align-items: center;
            .text {
              margin-left: 1rem;
            }
          }
          .downloadNum {
            margin-top: 2rem;
            font-size: 1.3rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #6e8db8;
            span {
              color: #fb5600;
              font-weight: bold;
            }
          }
          // .downloadBtn {
          //   overflow: hidden;
          //   position: relative;
          //   cursor: pointer;
          //   margin-top: 72px;
          //   width: 318px;
          //   height: 106px;
          //   background-image: url('../../static/img/downLoad.png');
          //   img {
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     animation: guang 1.2s 1s infinite both;
          //     width: 100%;
          //     height: 100%;
          //   }
          // }
          .bottomDownLoad {
            margin-top: 2rem;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            margin-bottom: 2rem;
            width: 10rem;
            height: auto;
            background: #1bb7f9;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            background-image: url('../../static/img/downLoad.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            .btnImg {
              width: 100%;
              height: auto;
            }
            .guang {
              position: absolute;
              left: 0;
              top: 0;
              animation: guang 1.2s 1s infinite both;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  /* 在720px及以下屏幕宽度应用的样式 */
}

@media screen and (min-width: 1200px) {
  .homeWrap {
    background: rgba(55, 133, 239, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 80px;
    .head {
      margin-top: -1px;
      overflow: hidden;
      width: 1920px;
      height: 680px;
      background-image: url('../../static/img/bg.png');
      opacity: 1;
      display: flex;
      justify-content: center;
      .headContent {
        width: 1200px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        // margin-left: 360px;
        justify-content: center;
        .title {
          margin-bottom: 24px;
          display: flex;
          .mainTitle {
            font-size: 60px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 0;
          }
          .miniTitle {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            font-size: 24px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .describe {
          margin-bottom: 72px;
          font-size: 16px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          width: 460px;
        }
        .downLoad {
          overflow: hidden;
          position: relative;
          cursor: pointer;
          margin-bottom: 36px;
          margin-top: 20px;
          width: 318px;
          height: 106px;
          background: #1bb7f9;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          background-image: url('../../static/img/downLoad.png');
          .btnImg {
            width: 100%;
            height: auto;
          }
          .guang {
            position: absolute;
            left: 0;
            top: 0;
            animation: guang 1.2s 1s infinite both;
            width: 100%;
            height: 100%;
          }
        }
        .adapter {
          height: 20px;
          font-size: 14px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    //恢复数据类型
    .dataType {
      overflow: hidden;
      width: 1920px;
      // height: 968px;
      background: rgba(55, 133, 239, 0.1);
      border-radius: 0px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .dataSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          margin-top: 72px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
        }
        .describe {
          margin-top: 24px;
          font-size: 16px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .content {
        box-sizing: border-box;
        // height: 568px;
        width: 1200px;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 72px;
        .contentItem {
          box-sizing: border-box;
          width: 252px;
          height: 252px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          box-shadow: 0 2px 20px 1px rgba(116,165,219,.16);
          .icon {
            width: 112px;
            height: 112px;
          }
          .text {
            margin-top: 18px;
          }
          &:nth-child(-n+4) {
            margin-bottom: 20px;
          }
        }
      }
    }
    //恢复文件类型
    .fileType {
      overflow: hidden;
      box-sizing: border-box;
      width: 1920px;
      // height: 968px;
      background-color: #fff;
      border-radius: 0px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .fileSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          margin-top: 72px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
        }
        .describe {
          margin-top: 24px;
          font-size: 16px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #666666;
        }
      }

      .content {
        box-sizing: border-box;
        height: 568px;
        width: 1200px;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 72px;
        .contentItem {
          box-sizing: border-box;
          // height: 174px;
          width: 377px;
          .top {
            display: flex;
            // justify-content: center;
            align-items: center;
            .icon {
            }
            .name {
              font-size: 18px;
              margin-left: 24px;
              font-weight: bold;
            }
          }
          .bottom {
            // width: 100%;
            padding-left: 84px;
            font-size: 16px;
            line-height: 30px;
          }
          &:nth-child(-n+4) {
            margin-bottom: 20px;
          }
        }
        .contentItem:nth-child(even) {
          margin-left: 396px;
        }
      }
    }
    //三步恢复
    .recoverySteps {
      overflow: hidden;
      box-sizing: border-box;
      width: 1920px;
      // height: 668px;
      background: rgba(55, 133, 239, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-top: 72px;
        font-size: 36px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: bold;
        color: #333333;
        position: relative;
        z-index: 99;
      }
      .content {
        width: 1200px;
        margin-top: 115px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 72px;
        .contentItem {
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
          width: 384px;
          height: 253px;
          background: #ffffff;
          border-radius: 16px 16px 16px 16px;

          &:hover {
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            border: 2px solid #1bb7f9;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          .circle {
            text-align: center;
            line-height: 110px;
            position: absolute;
            top: -55px;
            left: 50%;
            transform: translateX(-50%);
            width: 110px;
            height: 110px;
            background: #ffffff;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 50%;
            font-size: 20px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #333333;
          }
          .imageText {
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            .left {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              font-size: 28px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: bold;
              color: #333333;
            }
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
          }
        }
        .active {
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          border: 2px solid #1bb7f9;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
      }
    }
    //评论
    .comment {
      overflow: hidden;
      box-sizing: border-box;
      width: 1920px;
      // height: 702px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .commentSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          margin-top: 72px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
        }
        .secondTitle {
          font-size: 16px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          margin-top: 24px;
        }
        .content {
          margin-bottom: 72px;
          width: 1200px;
          margin-top: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .contentItem {
            display: flex;
            flex-direction: column;
            .commentText {
              border-radius: 3px;
              position: relative;
              width: 260px;
              height: 230px;
              // background: rgba(55, 133, 239);
              display: flex;
              // justify-content: center;
              // align-items: center;
              .test {
                opacity: 0.05;
                width: 100%;
                height: 100%;
                position: absolute;
                // z-index: -100;
                background: rgba(55, 133, 239);
                .triangle {
                  transform: rotate(250deg);
                  bottom: 30px;
                  left: 10px;
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-right: 200px solid transparent;
                  border-right: 200px solid transparent;
                  border-top: 100px solid rgba(55, 133, 239);
                }
              }
              .text {
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #515760;
                opacity: inherit;
                line-height: 30px;
                padding: 36px 26px;
              }
            }
            .commentPeople {
              display: flex;

              margin-top: 66px;
              .avatar {
                border-radius: 50%;
              }
              .scoreAndName {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-left: 24px;
                .name {
                  font-size: 20px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #666666;
                }
                .score {
                  margin-top: 6px;
                }
              }
            }
          }
        }
      }
    }
    //下载
    .downLoad {
      overflow: hidden;
      box-sizing: border-box;
      width: 1920px;
      // height: 591px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(55, 133, 239, 0.1);
      .downloadSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          margin-top: 72px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            margin-left: 36px;
          }
          .icon {
            width: 85px; 
            height: 85px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .downloadNum {
          margin-top: 48px;
          font-size: 24px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #6e8db8;
          span {
            color: #fb5600;
            font-weight: bold;
          }
        }
        .bottomDownLoad {
          margin-bottom: 72px;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          margin-top: 72px;
          width: 318px;
          height: 106px;
          background-image: url('../../static/img/downLoad.png');
          .guang {
            position: absolute;
            left: 0;
            top: 0;
            animation: guang 1.2s 1s infinite both;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>