var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "root2"
  }, [_c('div', {
    staticClass: "bottomnav"
  }, [_c('div', {
    staticClass: "top"
  }, [_vm._m(0), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "more"
  }, [_c('router-link', {
    attrs: {
      "to": "/about"
    }
  }, [_c('div', {
    staticClass: "about"
  }, [_vm._v("关于我们")])]), _c('router-link', {
    attrs: {
      "to": "/agreement"
    }
  }, [_c('div', {
    staticClass: "use"
  }, [_vm._v("使用协议")])]), _c('router-link', {
    attrs: {
      "to": "/privacy"
    }
  }, [_c('div', {
    staticClass: "privacy"
  }, [_vm._v("隐私协议")])])], 1)])]), _vm._m(1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../static/img/logo.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "softName"
  }, [_vm._v("易兴软件")])]), _c('div', {
    staticClass: "company"
  }, [_vm._v("凡百科技（惠州）有限公司")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "copy bottom-item"
  }, [_vm._v("Copyright © 2023 凡百科技（惠州）有限公司")]), _c('a', {
    staticClass: "filings bottom-item",
    attrs: {
      "href": "https://beian.miit.gov.cn",
      "target": "_blank"
    }
  }, [_vm._v(" 粤ICP备2023131503号 ")]), _c('div', {
    staticClass: "police bottom-item"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../static/img/police.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" 网络警察提醒你 ")])]), _c('div', {
    staticClass: "reportCenter bottom-item"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../static/img/reportCenter.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" 中国互联网举报中心 ")])]), _c('div', {
    staticClass: "party bottom-item"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../static/img/party.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "text"
  }, [_c('a', {
    attrs: {
      "href": "https://beian.mps.gov.cn/#/query/webSearch?code=44133002100261",
      "rel": "noreferrer",
      "target": "_blank"
    }
  }, [_vm._v("粤公网安备44133002100261")])])])]);
}];
export { render, staticRenderFns };