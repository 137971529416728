import Vue from 'vue';

export default {
    install() {
        Vue.prototype.$common = {
            //注册的获取手机验证码
            registerSendCode(data) {
                return Vue.prototype.$http
                    .post('/api/auth/sendCode', data)
            },
            //用户注册
            register(data) {
                return Vue.prototype.$http
                    .post('/api/auth/register', data)
            },
            //登录
            login(data) {
                return Vue.prototype.$http
                    .post('/api/auth/login', data)
            },
            //退出登录
            logout() {
                return Vue.prototype.$http
                    .post('/api/auth/users/logout')
            },
            //重置密码 1设置密码 2旧密码修改方式 3手机短信方式
            resetPassword(data) {
                return Vue.prototype.$http
                    .post('/api/auth/password/update', data)
            },
            //获取用户信息
            getUserInfo() {
                return Vue.prototype.$http
                    .get('/api/auth/users/show')
            },
            //更新用户信息
            updateUserInfo(data) {
                return Vue.prototype.$http
                    .post('/api/auth/users/update', data)
            },
            //我的订单
            selectOrderList(pagemsg) {
                return Vue.prototype.$http
                    .get(`/api/web/goodsList/index?page=${pagemsg.page}&per_page=${pagemsg.per_page}`)
            },
            //查询字典列表
            selectDictList(dictArr) {
                return Vue.prototype.$http
                .post(`/api/common/dict/query`,{"dict_type":dictArr})
            },
            //请求微信登录二维码和绑定微信二维码 二维码类型type 1登录注册 2绑定微信号
            getWechatQrCode(data) {
                return Vue.prototype.$http
              .post(`api/auth/wechat/qrcode`,data)
            },
            //绑定账号
            bindAccount(data) {
                return Vue.prototype.$http
              .post(`api/auth/users/bind`,data)
            },
            //注销账号
            logoff(data) {
                return Vue.prototype.$http
            .post(`api/auth/users/delete`,data)
            }
        };
    },
}