<template>
  <div class="root">
    <div class="topnav">
      <div class="logo" @click="toWhere('/home')">
        <img src="../../static/img/logo.png" alt="" />
        <span>易兴软件</span>
      </div>
      <div class="number">
        全球超过<span :id="downloadNum === '1,231,437' ? '' : 'counter'">{{
          downloadNum
        }}</span
        >用户下载使用
      </div>
      <div class="download">免费下载(安全)</div>
      <div class="loginOrAvatar">
        <div class="login" v-if="!hasAuthorization" @click="toLogin">登录</div>
        <div class="avatar" v-else>
          <div class="img">
            <img src="../../static/img/头像3.png" alt="" />
          </div>
          <div class="menu">
            <div class="triangle"></div>
            <div class="tabs">
              <div class="tabsItem" @click="toWhere('/personalCenter')">
                个人中心
              </div>
              <div class="tabsItem" @click="toOrder">我的订单</div>
              <div class="tabsItem" @click="logout">退出登录</div>
            </div>
          </div>
        </div>
      </div>
      <div class="openMenu hideMenu" @click="showMenu = !showMenu">
        <i class="el-icon-s-unfold" style="colour: #fff"></i>
      </div>
    </div>
    <div class="mySubMenu" v-show="showMenu">
      <el-collapse @change="handleChange">
        <el-collapse-item
          title="立即下载"
          name="1"
          class="custom-collapse-item"
          :disabled="true"
        >
        </el-collapse-item>
        <el-collapse-item
          title="全球超过1,231,437下载"
          name="2"
          :disabled="true"
           class="custom-collapse-item"
        >
          <div></div>
        </el-collapse-item>
        <div class="loginOrAvatar">
          <div class="login" v-if="!hasAuthorization" @click="toLogin">
            登录
          </div>
          <div class="avatar" v-else>
            <div class="img" @click="changeLoginMenu">
              <img src="../../static/img/头像3.png" alt="" />
            </div>
            <div class="menu" v-show="showLoginMenu">
              <div class="triangle"></div>
              <div class="tabs">
                <div class="tabsItem" @click="toWhere('/personalCenter')">
                  个人中心
                </div>
                <div class="tabsItem" @click="toOrder">我的订单</div>
                <div class="tabsItem" @click="logout">退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </el-collapse>
    </div>
    <div class="mask" v-show="showMenu"></div>
  </div>
</template>

<script>
import router from '@/router'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      isSticky: false,
      hasAuthorization: '',
      showLoginMenu: false,
      showMenu: false,
    }
  },
  computed: {
    ...mapState(['downloadNum']),
  },
  methods: {
    toWhere(loaction) {
      console.log(this.$route.fullPath)
      if (loaction == this.$route.fullPath) return
      router.push(loaction)
    },
    toOrder() {
      if ('/personalCenter' == this.$route.fullPath) {
        this.$emit('changnActiveIndex', '2-1')
      } else {
        this.$router.push({
          path: '/personalCenter',
          query: { activeIndex: '2-1' },
        })
      }
    },
    logout() {
      //清除token
      localStorage.removeItem('Authorization')
      localStorage.removeItem('userInfo')
      this.$router.push('/login')
    },
    toLogin() {
      this.$router.push('/login')
    },
    //处理下载量数据动画
    handleNumChange() {
      if (this.downloadNum === '1,231,437') return
      // document.addEventListener('DOMContentLoaded', () => {
      const counterElement = document.getElementById('counter')
      const targetValue = 1231437
      const duration = 800 // Animation duration in milliseconds
      const interval = 10 // Update interval in milliseconds

      const increment = targetValue / (duration / interval)
      let currentValue = 0

      function updateCounter() {
        currentValue += increment
        counterElement.textContent = Math.round(currentValue).toLocaleString()

        if (currentValue < targetValue) {
          requestAnimationFrame(updateCounter)
        }
      }

      updateCounter()
      this.$store.commit('setDownloadNum', '1,231,437') // 更新 Vuex 中的状态
      // })
    },
    handleChange(val) {
      console.log(val)
    },
    changeLoginMenu() {
      this.showLoginMenu = !this.showLoginMenu
    },
  },
  mounted() {
    //处理下载量数字变化动画
    this.handleNumChange()
    this.hasAuthorization = localStorage.Authorization
  },
}
</script>

<style>
.custom-collapse-item .el-collapse-item__arrow {
  display: none;
}
.el-collapse-item.is-disabled .el-collapse-item__header {
    color: black;
}
</style>
<style lang="less" scoped >
// 下载量数字跳动动画
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and(max-width:480px) {
  html {
    font-size: 16px;
    .root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 80px;
      background: #131d2b;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: fixed;
      top: 0;
      z-index: 100;
      .topnav {
        padding: 0 15px;
        box-shadow: 0 4px 8px 1px rgba(255, 255, 255, 0.17);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        position: relative;
        .logo {
          scale: .7;
          cursor: pointer;
          margin-right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            width: 96px;
            height: 35px;
            font-size: 24px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #ffffff;
            -webkit-background-clip: text;
            margin-left: 12px;
          }
        }
        .number {
          margin-right: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 329px;
          height: 40px;
          font-size: 20px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #6e8db8;
          span {
            color: #fff;
            font-size: 28px;
          }
          .counter {
            animation: countUp 1s ease-out;
          }
          display: none;
        }
        .download {
          cursor: pointer;
          margin-right: 161px;
          width: 160px;
          height: 40px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #1bb7f9;
          transition: all 0.3s ease-in-out; /* 修正这里 */
          &:hover {
            background-color: #1bb7f9;
            color: #fff;
          }
          display: none;
        }
        .loginOrAvatar {
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          .login {
            cursor: pointer;
            width: 85px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #1bb7f9;
            border-radius: 9px 9px 9px 9px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .avatar {
            &:hover {
              .menu {
                opacity: 1;
              }
            }
            position: relative;
            .img {
              cursor: pointer;
              width: 44px;
              height: 44px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .menu {
              transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
              opacity: 0;
              border-radius: 5px;
              width: 110px;
              height: 136px;
              background: #ffffff;
              position: absolute;
              right: 0px;
              margin-top: 18px;
              box-sizing: border-box;
              .triangle {
                position: absolute;
                top: -8px;
                right: 10px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent; /* 左边的边 */
                border-right: 4px solid transparent; /* 右边的边 */
                border-bottom: 8px solid #fff; /* 底边 */
              }
              .tabs {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                // padding: 24px 23px;
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #666666;
                .tabsItem:hover {
                  cursor: pointer;
                  color: #1bb7f9;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .openMenu {
          color: #fff;
          font-size: 1.5rem;
        }
        .hideMenu {
        display: block;
      }
      }
      
      .mySubMenu {
        background-color: #fff;
        width: 100vw;
        position: absolute;
        top: 80px;
        .loginOrAvatar {
          line-height: 2rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .login {
            cursor: pointer;
            width: 85px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #1bb7f9;
            border-radius: 9px 9px 9px 9px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .avatar {
            // &:hover {
            //   .menu {
            //     opacity: 1;
            //   }
            // }
            position: relative;
            .img {
              cursor: pointer;
              width: 44px;
              height: 44px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .menu {
              transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
              // opacity: 0;
              border-radius: 5px;
              width: 7rem;
              padding: 1rem;
              height: 136px;
              background: #ffffff;
              position: absolute;
              // right: 0px;
              margin-top: 1px;
              box-sizing: border-box;
              // .triangle {
              //   position: absolute;
              //   top: -8px;
              //   right: 10px;
              //   width: 0;
              //   height: 0;
              //   border-left: 4px solid transparent; /* 左边的边 */
              //   border-right: 4px solid transparent; /* 右边的边 */
              //   border-bottom: 8px solid #fff; /* 底边 */
              // }
              .tabs {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                // padding: 24px 23px;
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #666666;
                .tabsItem:hover {
                  cursor: pointer;
                  color: #1bb7f9;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .el-collapse-item__header {
          width: 100%;
        }
        .el-collapse-item{
          line-height: 2rem;
        }
      }
      .mask {
        position: fixed;
        width: 10000px;
        height: 10000px;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: -1;
      }
    }
  }
}

@media screen and(min-width:481px) and (max-width: 719px) {
  html {
    font-size: 16px;
    .root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 80px;
      background: #131d2b;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: fixed;
      top: 0;
      z-index: 100;
      .topnav {
        padding: 0 15px;
        box-shadow: 0 4px 8px 1px rgba(255, 255, 255, 0.17);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        position: relative;
        .logo {
           scale: .7;
          cursor: pointer;
          margin-right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            width: 96px;
            height: 35px;
            font-size: 24px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #ffffff;
            -webkit-background-clip: text;
            margin-left: 12px;
          }
        }
        .number {
          margin-right: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 329px;
          height: 40px;
          font-size: 20px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #6e8db8;
          span {
            color: #fff;
            font-size: 28px;
          }
          .counter {
            animation: countUp 1s ease-out;
          }
          display: none;
        }
        .download {
          cursor: pointer;
          margin-right: 120px;
          width: 160px;
          height: 40px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #1bb7f9;
          transition: all 0.3s ease-in-out; /* 修正这里 */
          &:hover {
            background-color: #1bb7f9;
            color: #fff;
          }
          display: none;
        }
        .loginOrAvatar {
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          .login {
            cursor: pointer;
            width: 85px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #1bb7f9;
            border-radius: 9px 9px 9px 9px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .avatar {
            &:hover {
              .menu {
                opacity: 1;
              }
            }
            position: relative;
            .img {
              cursor: pointer;
              width: 44px;
              height: 44px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .menu {
              transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
              opacity: 0;
              border-radius: 5px;
              width: 110px;
              height: 136px;
              background: #ffffff;
              position: absolute;
              right: 0px;
              margin-top: 18px;
              box-sizing: border-box;
              .triangle {
                position: absolute;
                top: -8px;
                right: 10px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent; /* 左边的边 */
                border-right: 4px solid transparent; /* 右边的边 */
                border-bottom: 8px solid #fff; /* 底边 */
              }
              .tabs {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                // padding: 24px 23px;
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #666666;
                .tabsItem:hover {
                  cursor: pointer;
                  color: #1bb7f9;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .openMenu {
          color: #fff;
          font-size: 1.5rem;
        }
        .hideMenu {
        display: block;
      }
      }
      
      .mySubMenu {
        background-color: #fff;
        width: 100vw;
        position: absolute;
        top: 80px;
        .loginOrAvatar {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .login {
            cursor: pointer;
            width: 85px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #1bb7f9;
            border-radius: 9px 9px 9px 9px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .avatar {
            // &:hover {
            //   .menu {
            //     opacity: 1;
            //   }
            // }
            position: relative;
            .img {
              cursor: pointer;
              width: 44px;
              height: 44px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .menu {
              transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
              // opacity: 0;
              border-radius: 5px;
              width: 7rem;
              padding: 1rem;
              height: 136px;
              background: #ffffff;
              position: absolute;
              // right: 0px;
              margin-top: 1px;
              box-sizing: border-box;
              // .triangle {
              //   position: absolute;
              //   top: -8px;
              //   right: 10px;
              //   width: 0;
              //   height: 0;
              //   border-left: 4px solid transparent; /* 左边的边 */
              //   border-right: 4px solid transparent; /* 右边的边 */
              //   border-bottom: 8px solid #fff; /* 底边 */
              // }
              .tabs {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                // padding: 24px 23px;
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #666666;
                .tabsItem:hover {
                  cursor: pointer;
                  color: #1bb7f9;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .el-collapse-item__header {
          width: 100%;
        }
      }
      .mask {
        position: fixed;
        width: 10000px;
        height: 10000px;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: -1;
      }
    }
  }
}

@media screen and (min-width: 719px) and (max-width: 1199px) {
  html {
    font-size: 16px;
    .root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 80px;
      background: #131d2b;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: fixed;
      top: 0;
      z-index: 100;
      .topnav {
        padding: 0 15px;
        box-shadow: 0 4px 8px 1px rgba(255, 255, 255, 0.17);
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        position: relative;
        .logo {
           scale: .7;
          cursor: pointer;
          margin-right: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            width: 96px;
            height: 35px;
            font-size: 24px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #ffffff;
            -webkit-background-clip: text;
            margin-left: 12px;
          }
        }
        .number {
          margin-right: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 329px;
          height: 40px;
          font-size: 20px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #6e8db8;
          span {
            color: #fff;
            font-size: 28px;
          }
          .counter {
            animation: countUp 1s ease-out;
          }
          display: none;
        }
        .download {
          cursor: pointer;
          margin-right: 120px;
          width: 160px;
          height: 40px;
          background: #ffffff;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #1bb7f9;
          transition: all 0.3s ease-in-out; /* 修正这里 */
          &:hover {
            background-color: #1bb7f9;
            color: #fff;
          }
          display: none;
        }
        .loginOrAvatar {
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          .login {
            cursor: pointer;
            width: 85px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #1bb7f9;
            border-radius: 9px 9px 9px 9px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .avatar {
            &:hover {
              .menu {
                opacity: 1;
              }
            }
            position: relative;
            .img {
              cursor: pointer;
              width: 44px;
              height: 44px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .menu {
              transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
              opacity: 0;
              border-radius: 5px;
              width: 110px;
              height: 136px;
              background: #ffffff;
              position: absolute;
              right: 0px;
              margin-top: 18px;
              box-sizing: border-box;
              .triangle {
                position: absolute;
                top: -8px;
                right: 10px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent; /* 左边的边 */
                border-right: 4px solid transparent; /* 右边的边 */
                border-bottom: 8px solid #fff; /* 底边 */
              }
              .tabs {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                // padding: 24px 23px;
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #666666;
                .tabsItem:hover {
                  cursor: pointer;
                  color: #1bb7f9;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .openMenu {
          color: #fff;
          font-size: 1.5rem;
        }
        .hideMenu {
        display: block;
      }
      }
      
      .mySubMenu {
        background-color: #fff;
        width: 100vw;
        position: absolute;
        top: 80px;
        .loginOrAvatar {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .login {
            cursor: pointer;
            width: 85px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #1bb7f9;
            border-radius: 9px 9px 9px 9px;
            opacity: 1;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: bold;
            color: #ffffff;
          }
          .avatar {
            // &:hover {
            //   .menu {
            //     opacity: 1;
            //   }
            // }
            position: relative;
            .img {
              cursor: pointer;
              width: 44px;
              height: 44px;
              border-radius: 50%;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .menu {
              transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
              // opacity: 0;
              border-radius: 5px;
              width: 7rem;
              padding: 1rem;
              height: 136px;
              background: #ffffff;
              position: absolute;
              // right: 0px;
              margin-top: 1px;
              box-sizing: border-box;
              // .triangle {
              //   position: absolute;
              //   top: -8px;
              //   right: 10px;
              //   width: 0;
              //   height: 0;
              //   border-left: 4px solid transparent; /* 左边的边 */
              //   border-right: 4px solid transparent; /* 右边的边 */
              //   border-bottom: 8px solid #fff; /* 底边 */
              // }
              .tabs {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                // padding: 24px 23px;
                font-size: 16px;
                font-family: Source Han Sans SC, Source Han Sans SC;
                font-weight: 400;
                color: #666666;
                .tabsItem:hover {
                  cursor: pointer;
                  color: #1bb7f9;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        .el-collapse-item__header {
          width: 100%;
        }
      }
      .mask {
        position: fixed;
        width: 10000px;
        height: 10000px;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: -1;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .root {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
    background: #131d2b;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    position: fixed;
    top: 0;
    z-index: 100;
    .topnav {
      // box-shadow: 0 4px 8px 1px rgba(255, 255, 255, 0.17);
      box-sizing: border-box;
      display: flex;
      width: 1200px;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      display: flex;
      .logo {
        cursor: pointer;
        margin-right: 213px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 96px;
          height: 35px;
          font-size: 24px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 500;
          color: #ffffff;
          -webkit-background-clip: text;
          margin-left: 12px;
        }
      }
      .number {
        margin-right: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 329px;
        height: 40px;
        font-size: 20px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 400;
        color: #6e8db8;
        span {
          color: #fff;
          font-size: 28px;
        }
        .counter {
          animation: countUp 1s ease-out;
        }
      }
      .download {
        cursor: pointer;
        margin-right: 120px;
        width: 160px;
        height: 40px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: bold;
        color: #1bb7f9;
        transition: all 0.3s ease-in-out; /* 修正这里 */
        &:hover {
          background-color: #1bb7f9;
          color: #fff;
        }
      }
      .loginOrAvatar {
        display: flex;
        justify-content: center;
        align-items: center;
        .login {
          cursor: pointer;
          width: 85px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: #1bb7f9;
          border-radius: 9px 9px 9px 9px;
          opacity: 1;
          font-size: 16px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #ffffff;
        }
        .avatar {
          &:hover {
            .menu {
              opacity: 1;
            }
          }
          position: relative;
          .img {
            cursor: pointer;
            width: 44px;
            height: 44px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .menu {
            transition: opacity 0.3s ease; // 添加过渡效果，使变化更加平滑
            opacity: 0;
            border-radius: 5px;
            width: 110px;
            height: 136px;
            background: #efefef;
            position: absolute;
            right: 0px;
            margin-top: 18px;
            box-sizing: border-box;
            .triangle {
              position: absolute;
              top: -8px;
              right: 10px;
              width: 0;
              height: 0;
              border-left: 4px solid transparent; /* 左边的边 */
              border-right: 4px solid transparent; /* 右边的边 */
              border-bottom: 8px solid #fff; /* 底边 */
            }
            .tabs {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              // padding: 24px 23px;
              font-size: 16px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #666666;
              .tabsItem:hover {
                cursor: pointer;
                color: #1bb7f9;
                transition: 0.5s;
              }
            }
          }
        }
      }
      .hideMenu {
      display: none;
    }
    }
    .mySubMenu {
      display: none;
    }
  }
}


</style>