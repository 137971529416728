import _objectWithoutProperties from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const _excluded = ["children"];
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
/**
 * 路由权限控制方式：beforeEach | addRoutes | 两者结合
 * 这里封装了 addRoutes 方式，即 resetRoutes 与 filterMapRoutes
 */

import Vue from 'vue';
import Router from 'vue-router';
import scrollBehavior from './scrollBehavior';
import routes from './routes';
import registerInterceptor from './registerInterceptor';
Vue.use(Router);
const mode = 'hash';
// const mode = 'history'
const createRouter = function createRouter() {
  const base = mode === 'hash' ? '/' : process.env.BASE_URL;
  return new Router({
    mode,
    base,
    scrollBehavior
  });
};

/**
 * 全局唯一 Router 实例
 */
export const router = createRouter();

/**
 * 路由重置
 * @param {routes} newRoutes
 */
export const resetRoutes = function resetRoutes(newRoutes) {
  router.matcher = createRouter().matcher;
  newRoutes.forEach(route => router.addRoute(route));
  if (router.app) {
    const {
      path,
      query,
      hash
    } = router.currentRoute;
    router.replace({
      path,
      query: _objectSpread(_objectSpread({}, query), {}, {
        _resetRoutes: '1'
      }),
      hash
    }).then(() => router.replace({
      path,
      query,
      hash
    }));
  }
};

/**
 * 路由过滤（过滤出有权限的路由）
 * @param {(meta: object, route: routes[0]) => boolean} filterCallback
 * @returns {routes}
 */
export const filterMapRoutes = function filterMapRoutes(filterCallback) {
  const loop = curRoutes => curRoutes.filter(route => filterCallback(route.meta || {}, route)).map(_ref => {
    let {
        children
      } = _ref,
      newRoute = _objectWithoutProperties(_ref, _excluded);
    if (children) newRoute.children = loop(children);
    return newRoute;
  });
  return loop(routes);
};
const allowedPathsForUnauthenticatedUser = ['/', '/home', '/about', '/privacy', '/agreement']; // 允许未登录用户访问的路径
// 路由守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('Authorization');

  // 如果用户已登录，允许访问路由
  if (isAuthenticated || to.path === '/login' || to.path === '/home' || allowedPathsForUnauthenticatedUser.includes(to.path)) {
    next();
  } else {
    // 用户未登录，重定向到登录页面
    next('/login');
  }
});

/* 注册路由拦截器 */
registerInterceptor(router);
/* 初始化公共路由 */
resetRoutes(filterMapRoutes(meta => {
  return meta.roles == null; // 如何处理路由权限因项目而异...
}));
export default router;