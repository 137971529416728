import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _Tabs2 from "element-ui/lib/theme-chalk/tabs.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tabs from "element-ui/lib/tabs";
import _TabPane2 from "element-ui/lib/theme-chalk/tab-pane.css";
import "element-ui/lib/theme-chalk/base.css";
import _TabPane from "element-ui/lib/tab-pane";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _SkeletonItem2 from "element-ui/lib/theme-chalk/skeleton-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _SkeletonItem from "element-ui/lib/skeleton-item";
import _Skeleton2 from "element-ui/lib/theme-chalk/skeleton.css";
import "element-ui/lib/theme-chalk/base.css";
import _Skeleton from "element-ui/lib/skeleton";
import _Submenu2 from "element-ui/lib/theme-chalk/submenu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Submenu from "element-ui/lib/submenu";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _Row2 from "element-ui/lib/theme-chalk/row.css";
import "element-ui/lib/theme-chalk/base.css";
import _Row from "element-ui/lib/row";
import _Popover2 from "element-ui/lib/theme-chalk/popover.css";
import "element-ui/lib/theme-chalk/base.css";
import _Popover from "element-ui/lib/popover";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _MenuItemGroup2 from "element-ui/lib/theme-chalk/menu-item-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItemGroup from "element-ui/lib/menu-item-group";
import _MenuItem2 from "element-ui/lib/theme-chalk/menu-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItem from "element-ui/lib/menu-item";
import _Menu2 from "element-ui/lib/theme-chalk/menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Menu from "element-ui/lib/menu";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _InfiniteScroll2 from "element-ui/lib/theme-chalk/infinite-scroll.css";
import "element-ui/lib/theme-chalk/base.css";
import _InfiniteScroll from "element-ui/lib/infinite-scroll";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _CollapseItem2 from "element-ui/lib/theme-chalk/collapse-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CollapseItem from "element-ui/lib/collapse-item";
import _Collapse2 from "element-ui/lib/theme-chalk/collapse.css";
import "element-ui/lib/theme-chalk/base.css";
import _Collapse from "element-ui/lib/collapse";
import _Col2 from "element-ui/lib/theme-chalk/col.css";
import "element-ui/lib/theme-chalk/base.css";
import _Col from "element-ui/lib/col";
import _Checkbox2 from "element-ui/lib/theme-chalk/checkbox.css";
import "element-ui/lib/theme-chalk/base.css";
import _Checkbox from "element-ui/lib/checkbox";
import _Cascader2 from "element-ui/lib/theme-chalk/cascader.css";
import "element-ui/lib/theme-chalk/base.css";
import _Cascader from "element-ui/lib/cascader";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";
/* 后期优化：手动把不需要用到的组件注释掉 */

import Vue from 'vue';
import './rewrite/index.less';

// Vue.use(Alert)
// Vue.use(Aside)
// Vue.use(Autocomplete)
// Vue.use(Avatar)
// Vue.use(Backtop)
// Vue.use(Badge)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
Vue.use(_Button);
// Vue.use(ButtonGroup)
// Vue.use(Calendar)
// Vue.use(Card)
// Vue.use(Carousel)
// Vue.use(CarouselItem)
Vue.use(_Cascader);
// Vue.use(CascaderPanel)
Vue.use(_Checkbox);
// Vue.use(CheckboxButton)
// Vue.use(CheckboxGroup)
Vue.use(_Col);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
// Vue.use(ColorPicker)
// Vue.use(Container)
Vue.use(_DatePicker);
Vue.use(_Dialog);
// Vue.use(Divider)
// Vue.use(Drawer)
// Vue.use(Dropdown)
// Vue.use(DropdownItem)
// Vue.use(DropdownMenu)
// Vue.use(Footer)
Vue.use(_Form);
Vue.use(_FormItem);
// Vue.use(Header)
// Vue.use(Icon)
// Vue.use(Image)
Vue.use(_InfiniteScroll);
Vue.use(_Input);
// Vue.use(InputNumber)
// Vue.use(Link)
// directive
Vue.use(_Loading);
Vue.prototype.$loading = _Loading.service;
// Vue.use(Main)
Vue.use(_Menu);
Vue.use(_MenuItem);
Vue.use(_MenuItemGroup);
Vue.prototype.$message = _Message;
// Vue.prototype.$msgbox = MessageBox
// Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = _MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$notify = Notification
Vue.use(_Option);
// Vue.use(OptionGroup)
// Vue.use(PageHeader)
// Vue.use(Pagination)
// Vue.use(Popconfirm)
Vue.use(_Popover);
// Vue.use(Progress)
// Vue.use(Radio)
// Vue.use(RadioButton)
// Vue.use(RadioGroup)
// Vue.use(Rate)
Vue.use(_Row);
// Vue.use(Scrollbar)
Vue.use(_Select);
// Vue.use(Slider)
// Vue.use(Spinner)
// Vue.use(Step)
// Vue.use(Steps)
Vue.use(_Submenu);
// Vue.use(Switch)
Vue.use(_Skeleton);
Vue.use(_SkeletonItem);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_TabPane);
Vue.use(_Tabs);
// Vue.use(Tag)
// Vue.use(Timeline)
// Vue.use(TimelineItem)
// Vue.use(TimePicker)
// Vue.use(TimeSelect)
Vue.use(_Tooltip);
// Vue.use(Transfer)
// Vue.use(Tree)
// Vue.use(Upload)