import _objectSpread from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _each from "lodash/each";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import axios from 'axios';
import stringify from 'qs/lib/stringify';
import dateFns_format from 'date-fns/format';
import download from './download';
import Vue from 'vue';
export { download // 附件下载
};

/**
 * 将列表转成对象
 * @param {object[]} list
 * @param {string} [valueKey]
 * @param {string} [labelKey]
 * @returns {object}
 */
export const listToMap = function listToMap(list, valueKey, labelKey) {
  valueKey = valueKey || 'value';
  labelKey = labelKey || 'label';
  const map = {};
  _each(list, item => {
    map[item[valueKey]] = item[labelKey];
  });
  return map;
};

/**
 * 时间格式化
 * @param {string | number | Date} date
 * @param {string} [format] 官方文档：https://date-fns.org/v1.30.1/docs/format#description
 * @example dateFormat('YYYY年MM月DD日 HH时mm分ss秒SSS毫秒 Z时区 Q季度 X秒时间戳 x毫秒时间戳')
 */
export const dateFormat = function dateFormat(date, format = 'YYYY-MM-DD') {
  if (!date) return '';
  if (format === '@iso') format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  return dateFns_format(date, format);
};

/**
 * 将对象序列化成参数
 * @param {object} data
 * @param {Parameters<qs.stringify>[1]} [options]
 */
export const qsStringify = function qsStringify(data, options) {
  options = _objectSpread({
    arrayFormat: 'repeat'
  }, options);
  return stringify(data, options);
};

/**
 * 将对象转成 formData
 * @typedef {string | number | boolean | File | Blob} Val
 * @param {{[key: string]: Val | Val[]}} data
 * @param {'repeat' | 'brackets' | 'indices'} [arrayFormat]
 */
export const toFormData = function toFormData(data, arrayFormat = 'repeat') {
  if (data instanceof FormData) return data;
  const formData = new FormData();
  _each(data, (val, key) => {
    if (val === undefined) return;
    if (Array.isArray(val)) {
      val = val.filter(v => v !== undefined);
      val.forEach((v, i) => {
        let k = key;
        if (arrayFormat === 'brackets') k += '[]';else if (arrayFormat === 'indices') k += `[${i}]`;
        formData.append(k, v === null ? '' : v);
      });
    } else {
      formData.append(key, val === null ? '' : val);
    }
  });
  return formData;
};

/**
 * 判断是否为 axios 取消的请求
 */
export const isCancel = axios.isCancel;

/**
 * 防抖函数
 */
// debounce.js

export const debounce = function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    // 如果之前设置过定时器，则清除之
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // 设置一个新的定时器，延迟执行传入的函数
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/**
 * 判断用户是否登录
 */
export const userIsLogin = () => {
  // 从 localStorage 或其他存储地方获取用户的身份验证令牌
  const Authorization = localStorage.getItem('Authorization');

  // 如果身份验证令牌存在且不为空，则认为用户已登录
  return Authorization && Authorization !== '';
};

/**
 * 设置cookie()
 **/
export const setCookie = (name, value, day) => {
  let date = new Date();
  date.setDate(date.getDate() + day);

  //base64加密()
  var baseScrect = window.btoa(value);
  document.cookie = name + '=' + baseScrect + ';expires=' + date;
};

/**
* 获取cookie(分为对称加密与非对称加密两种方式 aes加密)
**/
export const getCookie = name => {
  // MD5加密方式
  let reg = RegExp(name + '=([^;]+)');
  let arr = document.cookie.match(reg);
  if (arr) {
    return arr[1];
  } else {
    return '';
  }
};

/**
* 删除cookie(加密方式分为对称加密和非对称)
**/
export const delCookie = name => {
  setCookie(name, null, -1);
};