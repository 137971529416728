<template>
  <div class="loginWrap">
    <router-link to="/home">
      <div class="logo">
        <img src="../../static/img/logo（易兴软件带字）竖版.png" alt="" />
      </div>
    </router-link>
    <div class="midContent">
      <div class="loginPic">
        <img src="../../static/img/2.5D.png" alt="" />
      </div>
      <!-- 登录的表单 包括手机号验证码登录和密码登录 -->
      <div class="loginForm" v-show="!isForget && !isShowRegister">
        <p class="loginText"><i class="el-icon-back" @click="toHome"></i>欢迎登录</p>
        <div class="form">
          <!-- 顶部tab切换 -->
          <div class="tab">
            <div
              :class="{ pwdTab: true, active: activeTab === 'code' }"
              @click="setActiveTab('code')"
            >
              微信登录
            </div>
            <div
              :class="{ phoneTab: true, active: activeTab === 'phone' }"
              @click="setActiveTab('phone')"
            >
              验证码登录
            </div>
            <div
              :class="{ pwdTab: true, active: activeTab === 'password' }"
              @click="setActiveTab('password')"
            >
              密码登录
            </div>
          </div>
          <!-- 中间登录表单 -->
          <div class="formEle">
            <!-- 验证码登录的表单 -->
            <el-form
              ref="codeForm"
              :model="codeForm"
              :rules="codeRules"
              v-show="activeTab === 'phone'"
            >
              <div class="code">
                <el-form-item
                  label-width="0"
                  prop="phoneNumber"
                >
                  <div class="phone">
                    <el-input
                      v-model="codeForm.phoneNumber"
                      placeholder="请输入手机号码"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item label-width="0"  prop="code">
                  <div class="myCode">
                    <el-input
                      style="position: relative"
                      v-model="codeForm.code"
                      placeholder="请输入验证码"
                    >
                    </el-input>
                    <div
                      class="codeTime phoneLoginCode"
                      @click="
                        sendVerificationCode(
                          'codeForm',
                          'phoneNumber',
                          codeForm.phoneNumber,
                        )
                      "
                      :class="{ disabled: countdown > 0 }"
                    >
                      {{
                        countdown > 0 ? `${countdown} 秒后重发` : '发送验证码'
                      }}
                    </div>
                    <!-- <div class="sendCode">发送验证码</div> -->
                  </div>
                </el-form-item>
                <div class="wrap">
                  <div class="remember">
                    <el-checkbox v-model="checked">保持登录</el-checkbox>
                  </div>
                  <!-- <div class="registerBtn" @click="toRegister">立即注册</div> -->
                </div>
              </div>
            </el-form>
            <!-- 密码登录的表单 -->
            <el-form
              ref="passwordForm"
              :model="passwordForm"
              :rules="passwordRules"
              v-show="activeTab === 'password'"
            >
              <div class="password">
                <el-form-item label-width="0" prop="username">
                  <div class="username">
                    <el-input
                      v-model="passwordForm.username"
                      placeholder="请输入手机号"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item label-width="0" prop="password">
                  <div class="passwordInput">
                    <el-input
                      v-model="passwordForm.password"
                      placeholder="请输入密码"
                      show-password
                    ></el-input>
                  </div>
                </el-form-item>
                <div class="wrap">
                  <div class="remember">
                    <el-checkbox v-model="checked">保持登录</el-checkbox>
                  </div>
                  <div class="forget" @click="forgetPwd">忘记密码</div>
                </div>
              </div>
            </el-form>
          </div>
          <!-- 微信二维码登录 -->
          <div class="wechat" v-show="activeTab == 'code'">
            <!-- 二维码主体 -->
            <div class="wechatCode" v-show="wechatQrcode">
              <img :src="wechatQrcode" alt=""/>
              <!-- 二维码失效遮罩 -->
              <div
                class="mask"
                v-if="showWechatCodeMask"
                @click="getWechatQrCode"
              >
                <i class="el-icon-refresh-left"></i>
                <span>已失效,点击刷新</span>
              </div>


              <!-- 未勾选协议遮罩 -->
              <div
                class="mask"
                v-if="!checkAgreement"
              >
                <!-- <i class="el-icon-refresh-left"></i> -->
                <span>请先同意并勾选</span>
                <span>用户协议和隐私政策</span>
              </div>
            </div>
            <div v-show="!wechatQrcode" class="placeholder" v-loading="true" style="width: 15.375rem;height: 15.375rem"></div>
            <!-- 底部微信登录描述 -->
            <div class="wechatDescribe">
              <div class="icon">
                <img src="../../static/img/wechat@2x.png" alt="" />
              </div>
              <p>微信扫码即可完成注册登录</p>
            </div>
          </div>


            <!-- 登录按钮 -->
          <div
            class="loginBtn"
            @click="login()"
            :class="showLoading ? 'disabled' : ''"
            v-loading="showLoading"
            element-loading-background="rgba(192,192,192,0.8)"
            v-show="activeTab !== 'code'"
          >
            <div class="text">立即登录</div>
          </div>

          <!-- 条款协议 -->
          <div class="rule">
            <div :key="animationKey" class="dialog-box animate__animated animate__headShake" v-show="showCheckAgreement">
                <div class="arrow"></div>
                请先同意并勾选上述协议
              </div>
            <div class="myCheckBox">
              <el-checkbox v-model="checkAgreement"></el-checkbox>
            </div>
            <div class="text">
              手机号未注册将会自动创建账号。注册登录即代表您已阅读并同意易兴软件<router-link
              to="/agreement"
              ><span>用户协议</span></router-link>和<router-link to="/privacy"><span>隐私协议</span></router-link>
            </div>
            </div>
        </div>
      </div>
      <!-- 注册 -->
      <div class="register" v-show="isShowRegister">
        <p class="registerText">注册</p>
        <div class="form">
          <div class="formEle">
            <!-- 注册表单 -->
            <el-form
              ref="registerForm"
              :model="registerForm"
              :rules="registerRules"
              class="myRegisterForm"
            >
              <!-- 输入注册手机号 -->
              <div class="registerPhoneNumber registerItem">
                <el-form-item
                  label-width="0"
                  prop="registerPhoneNumber"
                >
                  <div class="registerPhoneNumberInput">
                    <el-input
                      v-model="registerForm.registerPhoneNumber"
                      placeholder="请输入手机号"
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
              <!-- 输入注册验证码 -->
              <div class="getCode registerItem">
                <el-form-item label-width="0"  prop="getCode">
                  <div class="getCodeInput">
                    <el-input
                      v-model="registerForm.getCode"
                      placeholder="请输入验证码"
                    ></el-input>
                    <div
                      class="codeTime registerCode"
                      @click="
                        sendVerificationCode(
                          'registerForm',
                          'registerPhoneNumber',
                          registerForm.registerPhoneNumber,
                        )
                      "
                      :class="{ disabled: countdown > 0 }"
                    >
                      {{
                        countdown > 0 ? `${countdown} 秒后重发` : '发送验证码'
                      }}
                    </div>
                  </div>
                </el-form-item>
              </div>
              <!-- 输入注册密码 -->
              <div class="registerPassword registerItem">
                <el-form-item
                  label-width="0"
                  prop="registerPassword"
                >
                  <div class="registerPasswordInput">
                    <el-input
                      v-model="registerForm.registerPassword"
                      placeholder="请设置密码"
                      show-password
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
              <!-- 确认密码 -->
              <div class="determineRegisterPassword registerItem">
                <el-form-item
                  label-width="0"
                  prop="determineRegisterPassword"
                >
                  <div class="determineRegisterPasswordInput">
                    <el-input
                      v-model="registerForm.determineRegisterPassword"
                      placeholder="请确认密码"
                      show-password
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <!-- 注册按钮 -->
          <div
            class="registerBtn"
            @click="register"
            :class="showLoading ? 'disabled' : ''"
            v-loading="showLoading"
          >
            <div class="text">注册</div>
          </div>
          <!-- 条款协议 -->
          <div class="back" @click="registerBack">返回</div>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div class="loginForgetPassword" v-show="isForget">
        <p class="forgetPasswordText">忘记密码</p>
        <div class="form">
          <div class="formEle">
            <!-- 重置密码表单 -->
            <el-form
              ref="forgetPasswordForm"
              :model="forgetPasswordForm"
              :rules="forgetPasswordFormRules"
              class="myforgetPasswordForm"
            >
              <!-- 输入重置密码手机号 -->
              <div class="forgetPasswordPhoneNumber forgetPasswordItem">
                <el-form-item
                  label-width="0"
                  prop="forgetPasswordPhoneNumber"
                >
                  <div class="forgetPasswordPhoneNumberInput">
                    <el-input
                      v-model="forgetPasswordForm.forgetPasswordPhoneNumber"
                      placeholder="请输入手机号"
                      autocomplete='off'
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
              <!-- 输入重置密码验证码 -->
              <div class="getCode forgetPasswordItem">
                <el-form-item
                  label-width="0"
                  prop="forgetPasswordCode"
                >
                  <div class="getCodeInput">
                    <el-input
                      v-model="forgetPasswordForm.forgetPasswordCode"
                      placeholder="请输入验证码"
                      autocomplete='off'
                    ></el-input>
                    <div
                      class="codeTime forgetPasswordCode"
                      @click="
                        sendVerificationCode(
                          'forgetPasswordForm',
                          'forgetPasswordPhoneNumber',
                          forgetPasswordForm.forgetPasswordPhoneNumber,
                        )
                      "
                      :class="{ disabled: countdown > 0 }"
                    >
                      {{
                        countdown > 0 ? `${countdown} 秒后重发` : '发送验证码'
                      }}
                    </div>
                  </div>
                </el-form-item>
              </div>
              <!-- 输入重置密码 -->
              <div class="forgetPasswordPassword forgetPasswordItem">
                <el-form-item
                  label-width="0"
                  prop="forgetPasswordPassword"
                >
                  <div class="forgetPasswordPasswordInput">
                    <el-input
                      v-model="forgetPasswordForm.forgetPasswordPassword"
                      placeholder="请设置密码"
                      show-password
                      autocomplete='new-password'
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
              <!-- 确认密码 -->
              <div class="determineForgetPasswordPassword forgetPasswordItem">
                <el-form-item
                  label-width="0"
                  prop="determineForgetPasswordPassword"
                >
                  <div class="determineForgetPasswordPasswordInput">
                    <el-input
                      v-model="
                        forgetPasswordForm.determineForgetPasswordPassword
                      "
                      placeholder="请确认密码"
                      show-password
                      autocomplete='new-password'
                    ></el-input>
                  </div>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <!-- 注册按钮 -->
          <div
            class="resetPasswordBtn"
            @click="resetPassword"
            :class="showLoading ? 'disabled' : ''"
            v-loading="showLoading"
          >
            <div class="text">重置密码</div>
          </div>
          <!-- 条款协议 -->
          <div class="back" @click="resetPasswordBack">返回</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { qsStringify, debounce,setCookie,delCookie,getCookie } from '@/scripts/utils'
import { encrypt, decrypt } from '@/scripts/secret/aes.js'
import axios from 'axios'
export default {
  data() {
    // 设置手机号的验证规则
    const validateMobile = function (rule, value, callback) {
      let newValue = value.replace(/[^0-9]/gi, '')
      if (value !== newValue) {
        callback(new Error('请输入正确的手机号'))
      } else if (newValue.length !== 11) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      animationKey:0,//手动dom元素动画效果
      showCheckAgreement:true,//是否展示协议的提示
      checkAgreement: false,//是否同意协议
      timerForMask:'', //五分钟更新一次二维码
      timerForCode:'', // 微信扫码登录轮询更新状态计时器
      uuid:'', // 用户唯一标识
      showWechatCodeMask:false, // 微信登录二维码是否显示遮罩层
      wechatQrcode:'', // 微信登录二维码
      showLoading: false, //登录展示加载中
      activeTab: 'code', // 默认为手机号登录被选中
      isForget: false, //控制忘记密码表单
      isShowRegister: false, //控制注册的表单
      countdown: 0, // 倒计时
      timer: null, // 定时器
      localStorageKey: 'verificationCodeCountdown', // 存储在localStorage中的键名
      checked: true,
      //验证码登录的表单
      codeForm: {
        phoneNumber: '',
        code: '',
      },
      //密码登录的表单
      passwordForm: {
        username: '',
        password: '',
      },
      //注册的表单
      registerForm: {
        registerPhoneNumber: '',
        getCode: '',
        registerPassword: '',
        determineRegisterPassword: '',
      },
      //忘记密码的表单
      forgetPasswordForm: {
        forgetPasswordPhoneNumber: '',
        forgetPasswordCode: '',
        forgetPasswordPassword: '',
        determineForgetPasswordPassword: '',
      },
      //手机号验证码登录的表单验证规则
      codeRules: {
        phoneNumber: [
          { required: true, trigger: 'blur', validator: validateMobile }, //写入验证器
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            len: 6,
            pattern: /^[0-9]{6}$/,
            message: '请输入六位数字验证码',
          }, //六位数字验证码的验证规则
        ],
      },
      //密码登录的表单规则
      passwordRules: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            min: 6,
            max: 20,
            message: '密码长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
      },
      //注册的表单验证
      registerRules: {
        registerPhoneNumber: [
          { required: true, trigger: 'blur', validator: validateMobile }, //写入验证器
        ],
        getCode: [
          {
            required: true,
            trigger: 'blur',
            len: 6,
            pattern: /^[0-9]{6}$/,
            message: '请输入六位数字验证码',
          }, //写入验证器
        ],
        registerPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '密码长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
        determineRegisterPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' },
        ],
      },
      //忘记密码的表单验证
      forgetPasswordFormRules: {
        forgetPasswordPhoneNumber: [
          { required: true, trigger: 'blur', validator: validateMobile }, //写入验证器
        ],
        forgetPasswordCode: [
          {
            required: true,
            trigger: 'blur',
            len: 6,
            pattern: /^[0-9]{6}$/,
            message: '请输入六位数字验证码',
          }, //写入验证器
        ],
        forgetPasswordPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '密码长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
        determineForgetPasswordPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          {
            validator: this.validateForgetPasswordConfirmPassword,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    //箭头跳转到首页
    toHome() {
      this.$router.push('/home')
    },
    //tab的切换
    setActiveTab(tab) {
      this.activeTab = tab
      if (tab === 'phone') {
        this.$refs.codeForm.resetFields() // 切换到手机号登录时重置表单
      } else if(tab === 'code') {

      } else {
        this.$refs.passwordForm.resetFields() // 切换到密码登录时重置表单
      }
    },
    //跳转到忘记密码页面
    forgetPwd() {
      this.isForget = !this.isForget
    },
    //重置密码的返回按钮
    resetPasswordBack() {
      this.isForget = !this.isForget
    },
    //跳转到注册页面
    toRegister() {
      this.isShowRegister = true
      this.isShowCode = false
    },
    //注册页面返回
    registerBack() {
      this.isShowRegister = false
    },
    //确认密码的逻辑
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.registerForm.registerPassword) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    },
    //重置密码确认密码的逻辑
    validateForgetPasswordConfirmPassword(rule, value, callback) {
      if (value !== this.forgetPasswordForm.forgetPasswordPassword) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    },
    //发送验证码的请求封装
    simulateSendVerificationCode(transData) {
      //数据转换成www的格式
      const data = qsStringify({
        mobile: transData,
        type: this.isForget ? 4 : 1
      })
      this.$common
        .registerSendCode(data)
        .then((res) => {
          console.log('我是哦结果', res.data)
          this.$message.success('验证码发送成功')
        })
        .catch((error) => {
          console.log(error, '出错了')
          clearInterval(this.timer)
          //处理时间戳
          this.countdown = 0
          const endTimestamp = Date.now() + this.countdown * 1000
          localStorage.setItem(this.localStorageKey, endTimestamp.toString())
        })
      console.log(data)
    },
    //处理验证码逻辑的封装
    sendVerificationCode(formRefName, veri, phoneNumber) {
      const formRef = this.$refs[formRefName]
      // 验证指定的表单项
      formRef.validateField(veri, (errorMsg) => {
        if (errorMsg) {
          // 表单项验证失败
          console.log('失败')
        } else {
          console.log('成功')
          // 表单项验证通过，执行提交操作
          // 检查localStorage中是否有存储的倒计时结束时间戳
          const storedTimestamp = localStorage.getItem(this.localStorageKey)

          if (!storedTimestamp || parseInt(storedTimestamp, 10) < Date.now()) {
            // 模拟发送验证码的异步操作
            // 在实际应用中，这里应该是发送请求给后端，后端发送验证码，等待成功后再开始倒计时
            this.simulateSendVerificationCode(phoneNumber)

            // 设置倒计时为60秒
            this.countdown = 60

            // 存储倒计时结束的时间戳到localStorage
            const endTimestamp = Date.now() + this.countdown * 1000
            localStorage.setItem(this.localStorageKey, endTimestamp.toString())

            // 启动定时器，每秒减少倒计时
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--
              } else {
                // 倒计时结束，清除定时器
                clearInterval(this.timer)
              }
            }, 1000)
          } else {
            // 如果倒计时还未结束，不执行发送逻辑
            console.log('请等待倒计时结束后再发送验证码')
          }
        }
      })
    },
    //重置密码
    resetPassword() {
      this.showLoading = true
      // 判断表单的验证是否通过了
      this.$refs.forgetPasswordForm.validate((valid) => {
        if (valid) {
          const data = qsStringify({
            mobile: this.forgetPasswordForm.forgetPasswordPhoneNumber,
            password: encrypt(this.forgetPasswordForm.determineForgetPasswordPassword),
            password_confirmation:
            encrypt(this.forgetPasswordForm.forgetPasswordPassword),
            code: this.forgetPasswordForm.forgetPasswordCode,
            type: '3',
          })
          // 如果验证通过，执行提交逻辑
          this.$common.resetPassword(data).then((res) => {
            this.$message.success('重置密码成功')
            this.isForget = false
            this.$refs.forgetPasswordForm.resetFields()
          }).finally(() => {
            this.showLoading = false
          })
        } else {
          // 如果验证不通过，给出提示或其他处理
          this.showLoading = false
        }
      })
    },
    //注册
    register() {
      const data = qsStringify({
        mobile: this.registerForm.registerPhoneNumber,
        password: this.registerForm.registerPassword,
        password_confirmation: this.registerForm.determineRegisterPassword,
        code: this.registerForm.getCode,
      })
      // 判断表单的验证是否通过了
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          
          // 如果验证通过，执行提交逻辑
          this.$common
            .register(data)
            .then((res) => {
              this.$message.success('注册成功')
              this.isShowRegister = false
            })
            .catch((error) => {
              // console.log(error, '我是错误对象')
              // this.$message.error('出错了')
            }).finally(() => {
              this.showLoading = false
            })
        } else {
          // 如果验证不通过，给出提示或其他处理
        }
      })
    },
    //登录
    login(code=false) {
      //手机号登录
      if (this.activeTab == 'phone' && !code) {
        // 判断表单的验证是否通过了
        this.$refs.codeForm.validate((valid) => {
          //禁用登录按钮
          if (valid) {
            //如果勾选了同意协议
            if(this.checkAgreement) {
              this.showLoading = true
              const data = qsStringify({
              type: '2',
              code: this.codeForm.code,
              mobile: this.codeForm.phoneNumber,
            })
            // 如果验证通过，执行提交逻辑
            this.$common
              .login(data)
              .then((res) => {
                localStorage.setItem('Authorization', res.data.access_token)
                console.log('我是验证码登录成功的返回结果', res.data)
                this.$router.push('/home')
                this.$common.getUserInfo().then((res) => {
                  let result = res.data.data
                  result.sex = result.sex.toString()
                  const jsonString = JSON.stringify(result)
                  localStorage.setItem('userInfo', jsonString)
                  console.log(res.data.data, '用户信息')
                })
              })
              .catch((error) => {
                console.log(error, '我是错误对象')
                // this.$message.error(error.response.data.message)
              }).finally(() => {
                this.showLoading = false
              })
            }else { //如果没有勾选协议
              this.showCheckAgreement = true
              this.animationKey += 1;
            }
          } else {
            // 如果验证不通过，给出提示或其他处理
            // this.$message.error('表单验证失败，请检查输入')
            this.showLoading = false
          }
        })
      } else if(this.activeTab == 'password' && !code) {
        // 判断表单的验证是否通过了
        this.$refs.passwordForm.validate((valid) => {
          if (valid) {
            //判断用户是否勾选协议
            if(this.checkAgreement) {
              this.showLoading = true
              if(this.checked) {
              //保存帐号到cookie，有效期7天
              setCookie("user", this.passwordForm.username, 7);
              //保存密码到cookie，有效期7天
              setCookie("pwd", this.passwordForm.password, 7);
              }else {
                delCookie("user");
                delCookie("pwd");
              }


              // console.log('加密:',encrypt('pikesi39'))
              console.log('解密:',decrypt(encrypt('pikesi39')))
              const data = qsStringify({
                type: '1',
                password: encrypt(this.passwordForm.password),
                mobile: this.passwordForm.username,
              })
              // 如果验证通过，执行提交逻辑
              this.$common.login(data).then((res) => {
                localStorage.setItem('Authorization', res.data.access_token)
                console.log('我是密码登录成功的返回结果', res.data)
                this.$router.push('/home')
                this.$common.getUserInfo().then((res) => {
                  let result = res.data.data
                  result.sex = result.sex.toString()
                  const jsonString = JSON.stringify(result)
                  localStorage.setItem('userInfo', jsonString)
                  console.log(res.data.data, '用户信息')
                })
              }).finally(() => {
                this.showLoading = false
              })
            }else {
              this.animationKey += 1;
              this.showCheckAgreement = true
            }
            
          } else {
            // 如果验证不通过，给出提示或其他处理
            // this.$message.error('表单验证失败，请检查输入')
            this.showLoading = false
          }
        })
      }else if(this.activeTab == 'code' && code) {
        const data = qsStringify({
              type: '3',
              uuid: this.uuid,
            })
        this.$common.login(data).then((res) => {
          if(res.data.status_code == 200) {
          clearInterval(this.timerForCode)
          clearTimeout(this.timerForMask)
          this.timerForCode = null
          this.timerForMask = null
          localStorage.setItem('Authorization', res.data.access_token)
            console.log('我是微信扫码登录成功的返回结果', res.data)
            this.$router.push('/home')
            this.$common.getUserInfo().then((res) => {
              let result = res.data.data
              result.sex = result.sex.toString()
              const jsonString = JSON.stringify(result)
              localStorage.setItem('userInfo', jsonString)
              console.log(res.data.data, '用户信息')
            })
          }else if(res.data.status_code == 201) {
            //未登录
            // this.$message.warning(res.data.message)
            // clearInterval(this.timerForCode)
            // clearTimeout(this.timerForMask)
            // this.showWechatCodeMask = true
            //二维码已过期
            // this.$message.warning(res.data.message)
          }else {
            clearInterval(this.timerForCode)
            clearTimeout(this.timerForMask)
            this.timerForCode = null
            this.timerForMask = null
            this.showWechatCodeMask = true
            //二维码已过期
            this.$message.warning(res.data.message)
          }
        }).catch(() => {
          clearInterval(this.timerForCode)
          clearTimeout(this.timerForMask)
          this.timerForCode = null
          this.timerForMask = null
          this.showWechatCodeMask = true
        })
      }
    },
    //获取本地账号密码
    getuserpwd() {
      if (getCookie("user") != "" && getCookie("pwd") != "") {
        this.passwordForm.username = window.atob(getCookie("user"));
        this.passwordForm.password = window.atob(getCookie("pwd"));
        this.checked = true;
      }
    },
    //获取微信登录二维码(check用来手动控制第一次showWechatCodeMask为false时启动函数)
    getWechatQrCode(check=false) {
      // 获取二维码 uuid
      if(this.showWechatCodeMask || check) {
        clearInterval(this.timerForCode)
        clearTimeout(this.timerForMask);
        this.timerForCode = null;
        this.timerForMask = null;
        this.showWechatCodeMask = false;
        this.$common.getWechatQrCode({'type':1}).then((res) => {
        console.log(res.data.data, '微信二维码')
        this.wechatQrcode = res.data.data.qrcode_url
        this.uuid = res.data.data.uuid

        // 查询登录轮询
        
        this.timerForCode = setInterval(() => {
          if(this.activeTab == 'code') {
            this.login(true); // 确保正确调用
          }
        }, 3000);
      
        // 查询是否到时失效
        this.timerForMask = setTimeout(() => {
          this.showWechatCodeMask = true;
          //二维码失效停止验证登录轮询
          clearInterval(this.timerForCode);
          // this.$message.success('打的计时器')
        }, 5*60*1000);

      })
      }
    },
    //获取客户端传过来的URL里面的参数
    getQueryString() {
      console.log(this.$route.query.code)
      if(!this.$route.query.code) return
      //获取url中从客户端传过来的加密值
      // console.log(window.encodeURIComponent(this.$route.query.code),'query值')
      // console.log(this.$route.query.code,'未处理的query值')
      // console.log(window.decodeURIComponent (this.$route.query.code),'处理过的值')
      const data = {
          type:"4",
          // encryptData:window.decodeURIComponent (this.$route.query.code)
          encryptData:this.$route.query.code
        }
      
      this.$common.login(data).then((res) => {
        localStorage.setItem('Authorization', res.data.access_token)
        this.$router.push('/personalCenter')
        this.$common.getUserInfo().then((res) => {
          let result = res.data.data
          result.sex = result.sex.toString()
          const jsonString = JSON.stringify(result)
          localStorage.setItem('userInfo', jsonString)
          console.log(res.data.data, '用户信息')
        })
      })
    }, 
  },
  created() {
    //免登录登录
    this.getQueryString()
    const storedTimestamp = localStorage.getItem(this.localStorageKey)

    if (storedTimestamp && parseInt(storedTimestamp, 10) > Date.now()) {
      this.countdown = Math.ceil(
        (parseInt(storedTimestamp, 10) - Date.now()) / 1000,
      )

      // 启动定时器，每秒减少倒计时
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          // 倒计时结束，清除定时器
          clearInterval(this.timer)
        }
      }, 1000)
    }

    //获取本地账号密码
    this.getuserpwd()

    this.getWechatQrCode(true)

    if(this.activeTab == 'code') {

    }else {
      // clearInterval(this.timerForCode)
    }
  },
  mounted() {
    //给登录注册按钮绑定防抖事件
    // this.login = debounce(this.login, 1000)
    this.register = debounce(this.register, 1000)
    this.toSecondInputCode = debounce(this.toSecondInputCode, 1000)
    this.resetPassword = debounce(this.resetPassword, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timerForCode)
    clearTimeout(this.timerForMask)
    this.timerForCode = null
    this.timerForMask = null
  },
  watch: {
    //手机号验证码登录发送验证码变蓝
    codeForm: {
      handler(newVal, oldVal) {
        this.$refs.codeForm.validateField('phoneNumber', (errorMsg) => {
          if (errorMsg) {
            // 表单项验证失败
            console.log('失败')
            this.$el.querySelector('.phoneLoginCode').style.color = '#cccccc'
          } else {
            this.$el.querySelector('.phoneLoginCode').style.color = '#0da3e2'
          }
        })
      },
      deep: true,
    },
    //注册发送验证码变蓝
    registerForm: {
      handler(newVal, oldVal) {
        this.$refs.registerForm.validateField(
          'registerPhoneNumber',
          (errorMsg) => {
            if (errorMsg) {
              // 表单项验证失败
              console.log('失败')
              this.$el.querySelector('.registerCode').style.color = '#cccccc'
            } else {
              this.$el.querySelector('.registerCode').style.color = '#0da3e2'
            }
          },
        )
      },
      deep: true,
    },
    //忘记密码发送验证码变蓝
    forgetPasswordForm: {
      handler(newVal, oldVal) {
        this.$refs.forgetPasswordForm.validateField(
          'forgetPasswordPhoneNumber',
          (errorMsg) => {
            if (errorMsg) {
              // 表单项验证失败
              console.log('失败')
              this.$el.querySelector('.forgetPasswordCode').style.color =
                '#cccccc'
            } else {
              this.$el.querySelector('.forgetPasswordCode').style.color =
                '#0da3e2'
            }
          },
        )
      },
      deep: true,
    },
    activeTab:{
      handler(newVal, oldVal) {
        if(oldVal == 'code') {
          // this.getWechatQrCode(true)
        }else {
          // clearInterval(this.timerForCode)
          // clearTimeout(this.timerForMask);
        }
      },
      deep: true
    },
    checkAgreement:{
      handler(newVal, oldVal) {
        this.showCheckAgreement = !newVal
      }
    }
  },
}
</script>

<style scoped>
.formEle >>> .el-input__inner {
  border: 0px;
  box-shadow: 0 0 0 0px;
  border-bottom: 1px solid #eee;
  padding: 0;
}
.loginWrap {
  background: linear-gradient(
    91deg,
    rgba(85, 85, 230, 0.4) 0%,
    rgba(27, 182, 249, 0.4) 100%
  );
}
.loginForgetPassword >>> .el-form >>> .el-form-item__content {
  width: 100%;
}
.loginForgetPassword >>> .el-form >>> .el-input__inner {
  width: 100%;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 480px) {
  html {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    font-size: 16px;
    .logo {
      width: 99px;
      height: 105px;
      position: fixed;
      top: 60px;
      left: 72px;
      display: none;
    }
    .midContent {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .loginPic {
        width: 914px;
        height: 418px;
        display: none;
      }

      // 手机号登录和验证码登录
      .loginForm {
        padding: 0 20px;
        box-sizing: border-box;
        width: 100%;
        height: 687px;
        background: #ffffff;
        .codeTime {
          cursor: pointer;
          font-size: 14px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #cccccc;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .loginText {
          text-align: center;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          i {
            position: absolute;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            cursor: pointer;
          }
        }
        .form {
          .tab {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 20px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            -webkit-background-clip: text;
            font-size: 1rem;
            .phoneTab {
              // padding: 0 10px;
              // border-bottom: 2px solid #1bb7f9;
              // width: 100px;
              // flex: 1;
              // height: 29px;
              cursor: pointer;
            }
            .pwdTab {
              // padding: 0 10px;
              // border-bottom: 2px solid #1bb7f9;
              // width: 80px;
              // height: 29px;
              // flex: 1;
              cursor: pointer;
              // margin-left: 20px;
            }
            // .codeTab {
            //   width: 80px;
            //   height: 29px;
            //   cursor: pointer;
            // }

            .phoneTab.active,
            .pwdTab.active {
              border-bottom: 2px solid #1bb7f9; /* 点击时下边框颜色 */
              font-weight: bold; /* 点击时字体加粗 */
            }
          }
          .formEle {
            .code {
              .phone {
                margin-top: 64px;
              }
              .myCode {
                margin-top: 64px;
                .sendCode {
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #cccccc;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                }
              }
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .remember {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 12px;
                }
                .registerBtn {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #1bb7f9;
                }
              }
            }
            .password {
              .username {
                margin-top: 64px;
              }
              .passwordInput {
                margin-top: 64px;
              }
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .remember {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 12px;
                }
                .forget {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #1bb7f9;
                }
              }
            }
          }

          .wechat {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px 0;
            .wechatCode {
              position: relative;
              width: 200px;
              height: 200px;
              img {
                width: 100%;
                height: 100%;
              }
              .mask {
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                // background-color: rgba(0, 0, 0, .5);
                background: hsla(0, 0%, 100%, 0.8);
                backdrop-filter: blur(4px);
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                // font-weight: bold;
                // color: #000;
                span {
                  font-size: 14px;
                }
              }
            }
            .wechatDescribe {
              margin-top: 20px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                width: 20px;
                height: 20px;
                img {
                  margin-right: 5px;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .loginBtn {
            -webkit-user-select:none;/*webkit浏览器*/
            cursor: pointer;
            width: 80%;
            height: 3rem;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 52px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 1rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
              line-height: 29px;
            }
          }

          .disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
          }

          .rule {
            display: flex;
            position: absolute;
            color: #999999;
            width: 100vw;
            // height: 40px;
            font-size: 14px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            -webkit-background-clip: text;
            margin-top: 60px;
            span {
              color: #1bb7f9;
              cursor: pointer;
            }
            .dialog-box {
              position: absolute;
              background-color: black;
              border: 0.0625rem solid #ccc;
              border-radius: 0.3125rem;
              top: -50px;
              font-size: 12px;
              color: #fff;
              left: -15px;
              padding: 5px 15px;
              }
              .arrow {
                position: absolute;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent black transparent;
                transform: rotate(180deg);
                bottom: -20px;
                left: 10px;
              }
              .myCheckBox {
                padding-right: 0.625rem;
              }
          }
        }
      }
      //注册
      .register {
        box-sizing: border-box;
        padding: 0 60px;
        width: 532px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .registerText {
          // width: 72px;
          text-align: center;
          height: 52px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          line-height: 0px;
          -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 48px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .form {
          .formEle {
            .myRegisterForm {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .registerItem {
                margin-top: 20px;
              }
              // 注册手机号
              .registerPhoneNumber {
                width: 100%;
                .registerPhoneNumberInput {
                  // margin-top: 64px;
                }
              }
              // 注册验证码
              .getCode {
                width: 100%;
                .getCodeInput {
                  position: relative;
                  .codeTime {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #cccccc;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .disabled {
                    cursor: not-allowed;
                    // pointer-events: none;
                    opacity: 0.5;
                  }
                }
              }
              // 注册密码
              .registerPassword {
                width: 100%;
              }
              .determineRegisterPassword {
                width: 100%;
              }
            }
          }
          .registerBtn {
            cursor: pointer;
            width: 412px;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 72px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
            }
          }

          .disabled {
            cursor: not-allowed;
            // pointer-events: none;
            opacity: 0.5;
          }

          .back {
            cursor: pointer;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #0da3e2;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
      //重置密码
      .loginForgetPassword {
        box-sizing: border-box;
        padding: 20px 20px;
        width: 100%;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .forgetPasswordText {
          // width: 72px;
          text-align: center;
          height: 52px;
          font-size: 1.5rem;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          line-height: 0px;
          -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 48px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .form {
          .formEle {
            .myforgetPasswordForm {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .forgetPasswordItem {
                margin-top: 20px;
              }
              // 重置密码手机号
              .forgetPasswordPhoneNumber {
                width: 100%;
                .forgetPasswordPhoneNumberInput {
                  // margin-top: 64px;
                }
              }
              // 重置密码验证码
              .getCode {
                width: 100%;
                .getCodeInput {
                  position: relative;
                  .codeTime {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #cccccc;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .disabled {
                    cursor: not-allowed;
                    // pointer-events: none;
                    opacity: 0.5;
                  }
                }
              }
              // 重置密码
              .forgetPasswordPassword {
                width: 100%;
              }
              .determineForgetPasswordPassword {
                width: 100%;
              }
            }
          }
          .resetPasswordBtn {
            cursor: pointer;
            width: 80%;
            height: 3rem;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 1rem;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
              line-height: 29px;
            }
          }

          .disabled {
            cursor: not-allowed;
            // pointer-events: none;
            opacity: 0.5;
          }

          .back {
            cursor: pointer;
            font-size: 1rem;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #0da3e2;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 480px) and (max-width: 1500px) {
  html {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    .logo {
      width: 99px;
      height: 105px;
      position: fixed;
      top: 60px;
      left: 72px;
      display: none;
    }
    .midContent {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .loginPic {
        width: 914px;
        height: 418px;
        display: none;
      }

      // 手机号登录和验证码登录
      .loginForm {
        box-sizing: border-box;
        padding: 0 20px;
        min-width: 400px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .codeTime {
          cursor: pointer;
          font-size: 14px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #cccccc;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .loginText {
          text-align: center;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          i {
            position: absolute;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            cursor: pointer;
          }
        }
        .form {
          .tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            -webkit-background-clip: text;
            .phoneTab {
              // border-bottom: 2px solid #1bb7f9;
              width: 100px;
              height: 29px;
              cursor: pointer;
            }
            .pwdTab {
              // border-bottom: 2px solid #1bb7f9;
              width: 80px;
              height: 29px;
              cursor: pointer;
            }

            .phoneTab.active,
            .pwdTab.active {
              border-bottom: 2px solid #1bb7f9; /* 点击时下边框颜色 */
              font-weight: bold; /* 点击时字体加粗 */
            }
          }
          .formEle {
            .code {
              .phone {
                margin-top: 64px;
              }
              .myCode {
                margin-top: 64px;
                .sendCode {
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #cccccc;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                }
              }
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .remember {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 12px;
                }
                .registerBtn {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #1bb7f9;
                }
              }
            }
            .password {
              .username {
                margin-top: 64px;
              }
              .passwordInput {
                margin-top: 64px;
              }
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .remember {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 12px;
                }
                .forget {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #1bb7f9;
                }
              }
            }
          }

          .wechat {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px 0;
            .wechatCode {
              position: relative;
              width: 200px;
              height: 200px;
              img {
                width: 100%;
                height: 100%;
              }
              .mask {
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                // background-color: rgba(0, 0, 0, .5);
                background: hsla(0, 0%, 100%, 0.8);
                backdrop-filter: blur(4px);
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                // font-weight: bold;
                // color: #000;
                span {
                  font-size: 14px;
                }
              }
            }
            .wechatDescribe {
              margin-top: 20px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                width: 20px;
                height: 20px;
                img {
                  margin-right: 5px;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .loginBtn {
            -webkit-user-select:none;/*webkit浏览器*/
            cursor: pointer;
            width: 80%;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 52px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
            }
          }

          .disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
          }

          .rule {
            color: #999999;
            width: 406px;
            position: absolute;
            // height: 40px;
            display: flex;
            font-size: 14px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            -webkit-background-clip: text;
            margin-top: 60px;
            span {
              color: #1bb7f9;
              cursor: pointer;
            }
            .dialog-box {
              position: absolute;
              background-color: black;
              border: 0.0625rem solid #ccc;
              border-radius: 0.3125rem;
              top: -50px;
              font-size: 12px;
              color: #fff;
              left: -15px;
              padding: 5px 15px;
              }
              .arrow {
                position: absolute;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent black transparent;
                transform: rotate(180deg);
                bottom: -20px;
                left: 10px;
              }
              .myCheckBox {
                padding-right: 0.625rem;
              }
          }
        }
      }
      //注册
      .register {
        box-sizing: border-box;
        padding: 0 60px;
        width: 532px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .registerText {
          // width: 72px;
          text-align: center;
          height: 52px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          line-height: 0px;
          -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 48px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .form {
          .formEle {
            .myRegisterForm {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .registerItem {
                margin-top: 20px;
              }
              // 注册手机号
              .registerPhoneNumber {
                width: 100%;
                .registerPhoneNumberInput {
                  // margin-top: 64px;
                }
              }
              // 注册验证码
              .getCode {
                width: 100%;
                .getCodeInput {
                  position: relative;
                  .codeTime {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #cccccc;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .disabled {
                    cursor: not-allowed;
                    // pointer-events: none;
                    opacity: 0.5;
                  }
                }
              }
              // 注册密码
              .registerPassword {
                width: 100%;
              }
              .determineRegisterPassword {
                width: 100%;
              }
            }
          }
          .registerBtn {
            cursor: pointer;
            width: 412px;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 72px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
            }
          }

          .disabled {
            cursor: not-allowed;
            // pointer-events: none;
            opacity: 0.5;
          }

          .back {
            cursor: pointer;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #0da3e2;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
      //重置密码
      .loginForgetPassword {
        box-sizing: border-box;
        padding: 0 20px;
        min-width: 400px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .forgetPasswordText {
          // width: 72px;
          text-align: center;
          height: 52px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          line-height: 0px;
          -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 48px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .form {
          .formEle {
            .myforgetPasswordForm {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .forgetPasswordItem {
                margin-top: 20px;
              }
              // 重置密码手机号
              .forgetPasswordPhoneNumber {
                width: 100%;
                .forgetPasswordPhoneNumberInput {
                  // margin-top: 64px;
                }
              }
              // 重置密码验证码
              .getCode {
                width: 100%;
                .getCodeInput {
                  position: relative;
                  .codeTime {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #cccccc;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .disabled {
                    cursor: not-allowed;
                    // pointer-events: none;
                    opacity: 0.5;
                  }
                }
              }
              // 重置密码
              .forgetPasswordPassword {
                width: 100%;
              }
              .determineForgetPasswordPassword {
                width: 100%;
              }
            }
          }
          .resetPasswordBtn {
            cursor: pointer;
            width: 412px;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 72px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
            }
          }

          .disabled {
            cursor: not-allowed;
            // pointer-events: none;
            opacity: 0.5;
          }

          .back {
            cursor: pointer;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #0da3e2;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1080px) {
  html {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 0px 0px;
    .logo {
      width: 99px;
      height: 105px;
      position: fixed;
      top: 60px;
      left: 72px;
    }
    .midContent {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .loginPic {
        width: 914px;
        height: 418px;
        margin-right: 84px;
      }

      // 手机号登录和验证码登录
      .loginForm {
        position: relative;
        box-sizing: border-box;
        padding: 0 60px;
        width: 532px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .codeTime {
          cursor: pointer;
          font-size: 14px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: 400;
          color: #cccccc;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        .loginText {
          text-align: center;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          i {
            position: absolute;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            cursor: pointer;
          }
        }
        .form {
          .tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            -webkit-background-clip: text;
            .phoneTab {
              // border-bottom: 2px solid #1bb7f9;
              width: 100px;
              height: 29px;
              cursor: pointer;
            }
            .pwdTab {
              // border-bottom: 2px solid #1bb7f9;
              width: 80px;
              height: 29px;
              cursor: pointer;
            }

            .phoneTab.active,
            .pwdTab.active {
              border-bottom: 2px solid #1bb7f9; /* 点击时下边框颜色 */
              font-weight: bold; /* 点击时字体加粗 */
            }
          }
          .formEle {
            .code {
              .phone {
                margin-top: 64px;
              }
              .myCode {
                margin-top: 34px;
                .sendCode {
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #cccccc;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                }
              }
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .remember {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 0px;
                }
                .registerBtn {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #1bb7f9;
                }
              }
            }
            .password {
              .username {
                margin-top: 64px;
              }
              .passwordInput {
                margin-top: 34px;
              }
              .wrap {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .remember {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  // margin-top: 12px;
                }
                .forget {
                  cursor: pointer;
                  font-size: 14px;
                  font-family: Source Han Sans SC, Source Han Sans SC;
                  font-weight: 400;
                  color: #1bb7f9;
                }
              }
            }
          }
          .wechat {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px 0;
            .wechatCode {
              position: relative;
              width: 246px;
              height: 246px;
              img {
                width: 100%;
                height: 100%;
              }
              .mask {
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                // background-color: rgba(0, 0, 0, .5);
                background: hsla(0, 0%, 100%, 0.8);
                backdrop-filter: blur(4px);
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                // font-weight: bold;
                // color: #000;
                span {
                  font-size: 14px;
                }
              }
            }
            .wechatDescribe {
              margin-top: 20px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

  
            .loginBtn {
            -webkit-user-select:none;/*webkit浏览器*/
            cursor: pointer;
            width: 412px;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 52px;
            // margin-left: 50%;
            // transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
            }
          }
          .rule {
            display: flex;
            color: #999999;
            width: 406px;
            // height: 40px;
            font-size: 14px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            -webkit-background-clip: text;
            position: absolute;
            bottom: 36px;
            // margin-top: 60px;
            span {
              color: #1bb7f9;
              cursor: pointer;
            }
            .dialog-box {
              position: absolute;
              background-color: black;
              border: 0.0625rem solid #ccc;
              border-radius: 0.3125rem;
              top: -50px;
              font-size: 12px;
              color: #fff;
              left: -15px;
              padding: 5px 15px;
              }
              .arrow {
                position: absolute;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent black transparent;
                transform: rotate(180deg);
                bottom: -20px;
                left: 10px;
              }
              .myCheckBox {
                padding-right: 0.625rem;
              }
          }
          .disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
          }

        }
      }
      //注册
      .register {
        box-sizing: border-box;
        padding: 0 60px;
        width: 532px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .registerText {
          // width: 72px;
          text-align: center;
          height: 52px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          line-height: 0px;
          -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 48px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .form {
          .formEle {
            .myRegisterForm {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .registerItem {
                margin-top: 20px;
              }
              // 注册手机号
              .registerPhoneNumber {
                width: 100%;
                .registerPhoneNumberInput {
                  // margin-top: 64px;
                }
              }
              // 注册验证码
              .getCode {
                width: 100%;
                .getCodeInput {
                  position: relative;
                  .codeTime {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #cccccc;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .disabled {
                    cursor: not-allowed;
                    // pointer-events: none;
                    opacity: 0.5;
                  }
                }
              }
              // 注册密码
              .registerPassword {
                width: 100%;
              }
              .determineRegisterPassword {
                width: 100%;
              }
            }
          }
          .registerBtn {
            cursor: pointer;
            width: 412px;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 72px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
            }
          }

          .disabled {
            cursor: not-allowed;
            // pointer-events: none;
            opacity: 0.5;
          }

          .back {
            cursor: pointer;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #0da3e2;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
      //重置密码
      .loginForgetPassword {
        box-sizing: border-box;
        padding: 0 60px;
        width: 532px;
        height: 687px;
        background: #ffffff;
        border-radius: 16px 16px 16px 16px;
        .forgetPasswordText {
          // width: 72px;
          text-align: center;
          height: 52px;
          font-size: 36px;
          font-family: Source Han Sans SC, Source Han Sans SC;
          font-weight: bold;
          color: #333;
          line-height: 0px;
          -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          margin-top: 48px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .form {
          .formEle {
            .myforgetPasswordForm {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              .forgetPasswordItem {
                margin-top: 20px;
              }
              // 重置密码手机号
              .forgetPasswordPhoneNumber {
                width: 100%;
                .forgetPasswordPhoneNumberInput {
                  // margin-top: 64px;
                }
              }
              // 重置密码验证码
              .getCode {
                width: 100%;
                .getCodeInput {
                  position: relative;
                  .codeTime {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans SC, Source Han Sans SC;
                    font-weight: 400;
                    color: #cccccc;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                  }
                  .disabled {
                    cursor: not-allowed;
                    // pointer-events: none;
                    opacity: 0.5;
                  }
                }
              }
              // 重置密码
              .forgetPasswordPassword {
                width: 100%;
              }
              .determineForgetPasswordPassword {
                width: 100%;
              }
            }
          }
          .resetPasswordBtn {
            cursor: pointer;
            width: 412px;
            height: 70px;
            background: #0da3e2;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 72px;
            margin-left: 50%;
            transform: translateX(-50%);
            .text {
              width: 80px;
              height: 29px;
              font-size: 20px;
              font-family: Source Han Sans SC, Source Han Sans SC;
              font-weight: 400;
              color: #ffffff;
              -webkit-background-clip: text;
              text-align: center;
            }
          }

          .disabled {
            cursor: not-allowed;
            // pointer-events: none;
            opacity: 0.5;
          }

          .back {
            cursor: pointer;
            font-size: 16px;
            font-family: Source Han Sans SC, Source Han Sans SC;
            font-weight: 400;
            color: #0da3e2;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>